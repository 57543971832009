import React, { useEffect, useMemo } from 'react';
import { PageProps } from 'gatsby';
import { gql, useMutation, useQuery, useSubscription } from '@apollo/client';
import { PageLoading, Spinner } from '../../components/Spinner';
import {
  Card,
  Col,
  Divider,
  Image,
  List,
  message,
  Row,
  Select,
  Space,
  Steps,
  Tabs,
  Tag,
  Typography,
} from 'antd';
import config from '../../modules/dynamic-config';
import Text from 'antd/lib/typography/Text';
import VSpace from '../../components/VSpace';
import moment from 'moment';
import { QuickForm } from '../../components/QuickForm';
import { filter, isEmpty, isNil, pick, reject, startCase } from 'lodash';
import { Helmet } from 'react-helmet';
import createPrivatePage from '../../utils/createPrivatePage';
import { UserOutlined } from '@ant-design/icons';
import Conversation from '../../components/Conversation';
import useCurrentUser from '../../modules/app/useCurrentUser';
import PhotoInput from './../../components/inputs/PhotoInput';

const SUBSCRIBE_BOOKING = gql`
  subscription MySubscription($code: String!) {
    Booking(limit: 1, offset: 0, where: { code: { _eq: $code } }) {
      id
      createdOn
      code
      pickupDate
      returnDate
      forRentId
      pickupAddress
      returnAddress
      specialRequest
      status
      paymentStatus
      quantity
      total
      paymentType
      conversationId
      pickupPhotos
      returnPhotos
      ForRent {
        id
        Brand {
          id
          User {
            id
            email
            id
            name
            phoneNumber
          }
        }
      }
      User {
        email
        id
        name
        phoneNumber
      }
      BookingLogs(limit: 100, order_by: { createdOn: desc }) {
        id
        message
        data
        createdOn
      }
    }
  }
`;

const UPDATE_BOOKING = gql`
  mutation MyMutation($id: uuid!, $_set: Booking_set_input = {}) {
    update_Booking_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
      createdOn
      code
      pickupDate
      returnDate
      forRentId
      pickupAddress
      returnAddress
      specialRequest
      status
      paymentStatus
      quantity
      total
      paymentType
      pickupPhotos
      returnPhotos
    }
  }
`;

const GET_USER = gql`
  query GetUser($id: uuid!) {
    User_by_pk(id: $id) {
      email
      id
      name
      role
    }
  }
`;

function BookingTracking(props: PageProps) {
  const params = new URLSearchParams(props.location.search);
  const hash = props.location.hash;
  const code = params.get('code')?.split('-')[1]; // JR-CODE
  const { data: currentUser } = useCurrentUser();
  const { data, loading } = useSubscription(SUBSCRIBE_BOOKING, {
    variables: {
      code,
    },
  });
  const [updateBooking, { loading: updating, data: updated }] =
    useMutation(UPDATE_BOOKING);

  const shouldShowPhotosTab =
    currentUser && ['MANAGER', 'PARTNER'].includes(currentUser.role);
  const record = data?.Booking?.[0];

  useEffect(() => {
    if (updated) {
      message.success('Data saved');
    }
  }, [updated]);

  const items = useMemo(() => {
    if (!record) return;
    const { total, paymentStatus, paymentType } = record;
    return [
      ...total.items,
      {
        title: 'App fee',
        totalPrice: total.appFee,
      },
      {
        title: 'Total',
        totalPrice: total.total,
      },
      {
        title: 'Payment Type',
        right: `${paymentType}${
          paymentStatus === 'COMPLETED' ? ' - Paid' : ''
        }`,
      },
    ];
  }, [record]);

  if (loading) {
    return <PageLoading />;
  }

  if (!record) {
    return <>PageNotFound</>;
  }

  const {
    pickupAddress: receiveAddress,
    pickupDate: receiveDate,
    returnAddress,
    returnDate,
    status,
    specialRequest,
    conversationId,
    pickupPhotos,
    returnPhotos,
  } = record;
  const bookingPartner = record.ForRent?.Brand?.User;
  const bookingOwner = record.User;

  return (
    <>
      <Helmet>
        <title>Rental JR-{record.code}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Row justify="center" style={{ padding: 16 }}>
        <Card
          title="Your Rental"
          extra={updating ? <Spinner /> : undefined}
          style={{ maxWidth: 400, width: '100%' }}
        >
          <VSpace direction="vertical">
            <Edit
              record={pick(record, [
                'id',
                'status',
                'paymentStatus',
                'paymentType',
              ])}
              onChange={(v) => {
                updateBooking({
                  variables: {
                    id: record.id,
                    _set: v,
                  },
                });
              }}
            />
            <VSpace direction="vertical" align="center">
              <Image
                width={100}
                src={`${config.api.url}/booking/qrcode/${record.id}`}
              />
              <Text strong>Rental code: JR-{record.code}</Text>
              <Tag title="Status">{status}</Tag>
            </VSpace>

            <Tabs defaultActiveKey={hash}>
              <Tabs.TabPane tab="Details" key="#1">
                <VSpace>
                  {bookingOwner && (
                    <VSpace size={1}>
                      <Text strong>User Info:</Text>
                      {bookingOwner.name && (
                        <Text>Name: {bookingOwner.name}</Text>
                      )}
                      {bookingOwner.email && (
                        <Text>Email: {bookingOwner.email}</Text>
                      )}
                      {bookingOwner.phoneNumber && (
                        <Text>Phone: {bookingOwner.phoneNumber}</Text>
                      )}
                    </VSpace>
                  )}

                  {bookingPartner && (
                    <VSpace size={1}>
                      <Text strong>Partner Info:</Text>
                      {bookingPartner.name && (
                        <Text>Name: {bookingPartner.name}</Text>
                      )}
                      {bookingPartner.email && (
                        <Text>Email: {bookingPartner.email}</Text>
                      )}
                      {bookingPartner.phoneNumber && (
                        <Text>Phone: {bookingPartner.phoneNumber}</Text>
                      )}
                    </VSpace>
                  )}

                  <VSpace size={1}>
                    <Text strong>Receive:</Text>
                    <Text>{receiveAddress?.title}</Text>
                    <Space>
                      <Space>
                        <Text>Date:</Text>
                        <Text strong>
                          {moment(receiveDate).format('DD MMMM YYYY')}
                        </Text>
                      </Space>
                      <Space>
                        <Text>Time:</Text>
                        <Text strong>
                          {moment(receiveDate).format('hh:mm a')}
                        </Text>
                      </Space>
                    </Space>
                  </VSpace>

                  <VSpace size={1}>
                    <Text strong>Return:</Text>
                    <Text>
                      {!returnAddress?.title
                        ? 'Same as receive'
                        : returnAddress.title}
                    </Text>
                    <Space>
                      <Space>
                        <Text>Date:</Text>
                        <Text strong>
                          {moment(returnDate).format('DD MMMM YYYY')}
                        </Text>
                      </Space>
                      <Space>
                        <Text>Time:</Text>
                        <Text strong>
                          {moment(returnDate).format('hh:mm a')}
                        </Text>
                      </Space>
                    </Space>
                  </VSpace>

                  <List
                    dataSource={items}
                    renderItem={(item: any) => {
                      return (
                        <List.Item>
                          <Row
                            justify="space-between"
                            style={{ width: '100%' }}
                          >
                            <Col>
                              <Text strong>{item.title}</Text>
                              {item.desc && (
                                <div>
                                  <Text>{item.desc}</Text>
                                </div>
                              )}
                            </Col>
                            <Col>
                              {item.right
                                ? item.right
                                : formatPrice(item.totalPrice)}
                            </Col>
                          </Row>
                        </List.Item>
                      );
                    }}
                  />
                  {!!specialRequest && (
                    <VSpace size={1}>
                      <Text strong>Special Request:</Text>
                      <Text>{specialRequest}</Text>
                    </VSpace>
                  )}
                </VSpace>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Logs" key="#2">
                <List
                  dataSource={record.BookingLogs}
                  renderItem={(item: any) => {
                    return (
                      <List.Item>
                        <VSpace size={1}>
                          <Typography.Text disabled>
                            {moment(new Date(item.createdOn)).format(
                              'DD MMMM YYYY hh:mm a'
                            )}
                          </Typography.Text>
                          <Typography.Text>{item.message}</Typography.Text>
                          <LogExtraInfo data={item.data} />
                        </VSpace>
                      </List.Item>
                    );
                  }}
                />
              </Tabs.TabPane>
              {conversationId && (
                <Tabs.TabPane tab="Support" key="#support">
                  <Conversation conversationId={conversationId} />
                </Tabs.TabPane>
              )}
              {shouldShowPhotosTab && (
                <Tabs.TabPane tab="Photos" key="#photos">
                  <VSpace>
                    <VSpace size={1}>
                      <Text strong>Received:</Text>
                      <PhotoInput
                        value={pickupPhotos}
                        onChange={(v) => {
                          updateBooking({
                            variables: {
                              id: record.id,
                              _set: {
                                pickupPhotos: v,
                              },
                            },
                          });
                        }}
                      />
                    </VSpace>
                    <VSpace size={1}>
                      <Text strong>Returned:</Text>
                      <PhotoInput
                        value={returnPhotos}
                        onChange={(v) => {
                          updateBooking({
                            variables: {
                              id: record.id,
                              _set: {
                                returnPhotos: v,
                              },
                            },
                          });
                        }}
                      />
                    </VSpace>
                  </VSpace>
                </Tabs.TabPane>
              )}
            </Tabs>
          </VSpace>
        </Card>
      </Row>
    </>
  );
}

const bookingStatusOptions = [
  { label: 'PROCESSING', value: 'PROCESSING' },
  { label: 'CONFIRMED', value: 'CONFIRMED' },
  { label: 'RECEIVED', value: 'RECEIVED' },
  { label: 'RETURNED', value: 'RETURNED' },
  { label: 'COMPLETED', value: 'COMPLETED' },
  { label: 'CANCELED', value: 'CANCELED' },
  { label: 'FAILED', value: 'FAILED' },
];

const paymentStatusOptions = [
  { label: 'PENDING', value: 'PENDING' },
  { label: 'CASH RECEIVED', value: 'COMPLETED' },
  { label: 'FAILED', value: 'FAILED' },
];

function Edit(props: {
  onChange: (value: any) => void;
  record: {
    id: string;
    status: string;
    paymentStatus: string;
    paymentType: string;
  };
}) {
  const { record } = props;
  const initialValues = record;
  const fields = useMemo(() => {
    const result = [
      {
        name: 'status',
        label: 'Rental Status',
        labelCol: { span: 10 },
        children: (
          <Select placeholder="Rental Status" options={bookingStatusOptions} />
        ),
      },
    ];
    if (record.paymentType === 'CASH') {
      result.push({
        name: 'paymentStatus',
        label: 'Cash Payment',
        labelCol: { span: 10 },
        children: (
          <Select placeholder="Payment Status" options={paymentStatusOptions} />
        ),
      });
    }
    return result;
  }, [record]);

  const { currentStep, steps } = useMemo(() => {
    const currentStep = [
      ['PROCESSING'],
      ['CONFIRMED'],
      ['COMPLETED', 'CANCELED', 'FAILED'],
    ].findIndex((item) => {
      return item.indexOf(record.status) > -1;
    });
    if (currentStep < 0) {
      return { currentStep: 0, steps: [] };
    }
    return {
      currentStep,
      steps: [
        'Processing',
        'Confirmed',
        currentStep === 2
          ? startCase(record.status.toLowerCase())
          : 'Completed',
      ],
    };
  }, [record.status]);

  return (
    <VSpace direction="vertical" size={30}>
      <Steps size="small" current={currentStep}>
        {steps.map((item, index) => {
          return <Steps.Step key={index} title={item} />;
        })}
      </Steps>
      <QuickForm
        initialValues={initialValues}
        fields={fields}
        onValuesChange={(_, v) => {
          props.onChange(v);
        }}
      />
    </VSpace>
  );
}

function LogExtraInfo(props: { data: any }) {
  const { userId, userRole } = props.data;
  const loadUser = useQuery(GET_USER, {
    variables: {
      id: userId,
    },
    skip: !userId,
    fetchPolicy: 'cache-first',
  });
  if (loadUser.loading) {
    return null;
  }
  const user = loadUser.data?.User_by_pk;
  const name = reject([user?.name, user?.email], isEmpty).join(' - ');
  const displayName = name || userId || userRole?.toUpperCase();
  if (!displayName) {
    return null;
  }
  return (
    <Space>
      <UserOutlined size={12} />
      <Typography.Text strong>{displayName}</Typography.Text>
    </Space>
  );
}

export const formatPrice = (
  amount: number,
  options?: { showPositive?: boolean }
) => {
  const { showPositive } = { showPositive: false, ...options };
  const isNegative = amount < 0;
  return (
    (isNegative ? '-' : showPositive ? '+' : '') +
    '$' +
    Math.abs(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  );
};

export default createPrivatePage(BookingTracking);
