import { MinusOutlined } from '@ant-design/icons';
import { Button, List } from 'antd';
import { cloneDeep, isNil, last } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import useControllableState from '../../../hooks/useControllableState';
import { InputBaseProps } from '../../../utils/common';

export type ArrayInputProps<T> = InputBaseProps<T[]> & {
  Input: React.FunctionComponent<any>;

  // @@@
  // temp support empty string or json
  // this use to detect shouldAddRow
  defaultRowValue?: string | number | Record<string, unknown>;

  maxRows?: number;
};

// try to get value as array
const cloneValue = (value: any) => {
  return cloneDeep(Array.isArray(value) ? value : isNil(value) ? [] : [value]);
};

export function ArrayInput<T = any>(props: ArrayInputProps<T>) {
  const { Input, defaultRowValue, maxRows } = props;
  const [value, onChange] = useControllableState({
    defaultValue: props.value,
    onChange: props.onChange,
  });

  const dataSource: T[] = useMemo(() => {
    const items = cloneValue(value);
    const lastRow = last(items);

    // check should add row
    let shouldAddRow = items.length === 0;
    if (lastRow) {
      // default is object row
      const isObjectRow = !['string', 'number'].includes(
        typeof defaultRowValue
      );
      const allValues = isObjectRow ? Object.values(lastRow) : [lastRow];
      shouldAddRow = allValues.some((v) => v !== undefined);
    }

    if (shouldAddRow) {
      items.push(defaultRowValue as T);
    }

    return maxRows ? items.slice(0, maxRows) : items;
  }, [value, onChange, maxRows]);

  function updateRow(index: number, ev: any) {
    const rowValue = ev?.target ? ev.target.value : ev;
    const nextValue = cloneValue(value);
    nextValue[index] = rowValue;
    onChange(nextValue);
  }

  function remove(index: number) {
    const nextValue = cloneValue(value);
    nextValue.splice(index, 1);
    onChange(nextValue);
  }

  return (
    <List>
      {dataSource.map((item, index) => {
        const isLastRow = index === dataSource.length - 1;
        return (
          <List.Item
            key={index}
            actions={[
              <Button
                size="small"
                shape="circle"
                icon={<MinusOutlined />}
                onClick={() => remove(index)}
                style={{ visibility: isLastRow ? 'hidden' : 'visible' }}
              />,
            ]}
          >
            <Input value={item} onChange={(ev: any) => updateRow(index, ev)} />
          </List.Item>
        );
      })}
    </List>
  );
}
