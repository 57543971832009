import { Col, Input, InputNumber, Row } from 'antd';
import React from 'react';
import useJsonInput from '../../../hooks/useJsonInput';
import { InputBaseProps } from '../../../utils/common';
import CheckboxInput from '../CheckboxInput';
import { DateInput } from '../DateInput';

export type ObjectInputProps = InputBaseProps<object> & {
  rows?: RowType[];
};

type RowType = {
  key: string;
  title?: string;
  default: any;
  type?: 'text' | 'number' | 'date' | 'checkbox'; // will add more supported types
  inputProps?: any;
};

export default function ObjectInput(props: ObjectInputProps) {
  const { rows = [] } = props;
  const { getInputProps } = useJsonInput(props);
  return (
    <Row gutter={16}>
      {rows.map((row) => {
        const { type = 'text', key, title, inputProps } = row;
        const C =
          {
            text: Input,
            number: InputNumber,
            date: DateInput,
            checkbox: CheckboxInput,
          }[type] || Input;

        return (
          <Col key={key} style={{ marginBottom: 16 }}>
            {React.createElement(C as any, {
              key,
              placeholder: title,
              ...inputProps,
              ...getInputProps(key),
            })}
          </Col>
        );
      })}
    </Row>
  );
}
