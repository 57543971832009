import { PageProps } from 'gatsby';
import { useMemo, useState } from 'react';
import Layout, { LayoutProps } from '../../components/Layout';
import { Helmet } from 'react-helmet';
import React from 'react';
import { useKeyData } from '../../modules/hasura/useKeyData';
import { Button, Card, Form, Modal, message } from 'antd';
import { Spinner } from '../../components/Spinner';
import { QuickForm } from '../../components/QuickForm';
import { getFormFields } from '../../modules/row-data/utils';
import { InputType } from '../../modules/row-data/types';
import VSpace from '../../components/VSpace';
import Checkbox from 'antd/lib/checkbox';
import FormItem from 'antd/es/form/FormItem';
import useBeFn from '../../modules/app/useBeFn';

export default function TermsConditions(props: PageProps) {
  const {
    save,
    data,
    loaded,
    loading: submitting,
  } = useKeyData('settings.staticContent');
  const [isNotify, setNotify] = useState(false);
  const [notifyTermsConditionsUpdatedResult, notifyTermsConditionsUpdated] =
    useBeFn('jot/notifyTermsConditionsUpdated');

  const breadcrumb = useMemo<LayoutProps['breadcrumb']>(() => {
    return [
      { title: 'Home', path: '/' },
      { title: 'Terms and Conditions', path: '#' },
    ];
  }, []);

  const fields = useMemo(() => {
    return getFormFields(
      {
        colFields: {
          terms: {
            col: {
              type: InputType.RICH_TEXT,
              options: {},
            },
            field: {
              name: 'terms',
              permissions: {
                create: true,
                select: true,
                update: true,
              },
            },
          },
        },
      } as any,
      'update'
    );
  }, []);

  const initialValues = data;

  const onFinish = async (values: any) => {
    try {
      await save({
        set: values,
      });
      if (isNotify) {
        await notifyTermsConditionsUpdated();
        setNotify(false);
      }
      Modal.success({
        content: 'Submit successful',
      });
    } catch (error) {
      message.error((error as any).toString(), 3);
    }
  };

  return (
    <Layout breadcrumb={breadcrumb}>
      <Helmet>
        <title>Terms and Conditions</title>
      </Helmet>
      <Card extra={!loaded && <Spinner />}>
        {loaded && (
          <QuickForm
            layout="vertical"
            name="basic"
            initialValues={initialValues}
            onFinish={onFinish}
          >
            {fields.map((field) => {
              return <Form.Item key={field.name as string} {...field} />;
            })}
            <VSpace>
              <Checkbox
                checked={isNotify}
                onChange={(e) => setNotify(e.target.checked)}
              >
                Email Users
              </Checkbox>
              <Button
                htmlType="submit"
                type="primary"
                loading={submitting}
                disabled={submitting}
              >
                Submit
              </Button>
            </VSpace>
          </QuickForm>
        )}
      </Card>
    </Layout>
  );
}
