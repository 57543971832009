import React from 'react';
import RelatedArrayInput from '../../../components/inputs/RelatedArrayInput';
import { InputType, RenderColumn, RenderField } from '../types';

export const renderField: RenderField = ({ col, field }) => {
  return React.createElement(RelatedArrayInput, {
    table: field.to as string,
    foreignKey: field.foreignKey as string,
  });
};

export const renderColumn: RenderColumn = ({ value }) => {
  return null;
};
