import React, { ReactNode } from 'react';
import { TableRowActionProps } from '../../components/TableList';
import { TableResource } from '../../modules/row-data/getResources';
import { InputType } from '../../modules/row-data/types';
import { MenuItem } from '../../utils/common';
import SiteBase from '../SiteBase';
import BookingTracking from './BookingTracking';
import PartnerSettings from './PartnerSettings';
import PayoutDetail from './PayoutDetail';
import BookingLastMessage from './BookingLastMessage';
import TermsConditions from './TermsConditions';

class Jottrip extends SiteBase {
  public appId = 'jottrip';
  public defaultAvatar = 'logo192.png';
  public siteTitle = 'Jottrip';
  public menuRoles = ['MANAGER', 'PARTNER'];
  public api = {
    url: 'https://be.jottrip.com',
    // url: 'https://fcc5-2405-4802-c178-77e0-c08d-ce55-bca5-3966.ngrok-free.app',
  };
  public graphql = {
    api: 'https://gql.jottrip.com/v1',
    url: 'https://gql.jottrip.com/v1/graphql',
    ws: 'wss://gql.jottrip.com/v1/graphql',
  };
  public media = {
    uploadUrl: 'S3', // use S3 instead of upload to server 'https://be.jottrip.com/file/upload'
  };
  public google = {
    mapApi: 'AIzaSyC_NxiMqFx7CEt4CpNVuyVWvHQGcmLZdQg', // jottrip
  };

  getMediaUrl(
    media: { filename: string; bucketName?: string },
    size: string = 'original'
  ): string {
    if (Array.isArray(media)) {
      return this.getMediaUrl(media[0]);
    }
    if (media.bucketName) {
      const key =
        {
          icon: '300x300',
          small: '300x300',
          large: '900x900_max',
          original: '1200x1200_max',
        }[size] || '900x900_max';
      const parts = media.filename.split('/');
      parts.splice(parts.length - 1, 0, key);
      // cloudfront to S3
      return `https://d2mm5v5bb6dsjk.cloudfront.net/${parts.join('/')}`;
    }

    const key =
      {
        icon: 'small',
        small: 'small',
        large: 'large',
        original: 'original',
      }[size] || 'large';
    // cloudfront to  be.jottrip.com/file/image
    return `https://d1bvvem76as0xf.cloudfront.net/${key}/${media.filename}`;
  }

  getCustomPages() {
    return {
      ...super.getCustomPages(),
      tracking: BookingTracking,
      'partner-settings': PartnerSettings,
      'payout-detail': PayoutDetail,
      'terms-and-conditions': TermsConditions,
    };
  }

  getColFields(key: string): TableResource['colFields'] | void {
    const field = {
      name: 'terms',
      permissions: {
        create: true,
        select: true,
        update: true,
      },
    };
    if (key === 'static-content') {
      return {
        terms: {
          col: {
            type: InputType.RICH_TEXT,
            options: {},
          },
          field: {
            ...field,
            name: 'terms',
          },
        },
      };
    }
    if (key === 'app') {
      return {
        appFee: {
          col: {
            type: InputType.NUMBER,
            options: {},
          },
          field: {
            ...field,
            name: 'appFee',
          },
        },
        notifyEmails: {
          col: {
            type: InputType.TEXT,
            options: {},
          },
          field: {
            ...field,
            name: 'notifyEmails',
          },
        },
      };
    }
  }

  getMainMenus(
    menus: MenuItem[],
    context: { user: { id: string; role: string } }
  ): MenuItem[] {
    const result = super.getMainMenus(menus, context);
    if (context.user.role === 'MANAGER') {
      result.push({
        title: 'Terms and Conditions',
        path: '/page/terms-and-conditions',
      });
      result.push({
        title: 'App Settings',
        path: '/settings/app',
      });
    }
    if (context.user.role === 'PARTNER') {
      result.push({
        title: 'Partner Settings',
        path: '/page/partner-settings',
      });
    }
    return result;
  }

  getRowActions(
    rowActions: TableRowActionProps,
    context: { table: string }
  ): TableRowActionProps {
    super.getRowActions(rowActions, context);
    if (context.table === 'Booking') {
      rowActions.push((record) => ({
        children: 'View Detail',
        key: 'detail',
        href: `/page/tracking?code=JR-${record.code}`,
        target: '_blank',
        type: 'default',
      }));
    }
    if (context.table === 'Payout') {
      rowActions.push((record) => ({
        children: 'View Detail',
        key: 'detail',
        href: `/page/payout-detail?id=${record.id}`,
        // target: '_blank',
        type: 'default',
      }));
    }
    if (context.table === 'PayoutBooking') {
      rowActions = [
        (record) => ({
          children: 'View Detail',
          key: 'detail',
          href: `/page/tracking?code=JR-${record.code}`,
          target: '_blank',
          type: 'default',
        }),
      ];
    }
    return rowActions;
  }

  getExtraHeaderRight(): ReactNode {
    return React.createElement(BookingLastMessage);
  }
}

const jottrip = new Jottrip();
export default jottrip;
export type BeType = {
  'jot/notifyTermsConditionsUpdated': {
    input: any;
    result: { success: boolean };
  };
};
