import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { WrapRootElementBrowserArgs, WrapRootElementNodeArgs } from 'gatsby';
import { client } from './src/modules/apollo';
import App from './src/modules/app';

export default function WrapRootElement({
  element,
}: WrapRootElementBrowserArgs | WrapRootElementNodeArgs) {
  return (
    <ApolloProvider client={client}>
      <App children={element} />
    </ApolloProvider>
  );
}
