import { Input } from 'antd';
import React from 'react';
import JsonInput from '../../../components/inputs/JsonInput';
import getRenders from '../../../utils/getRenders';
import {
  RenderColumn,
  RenderField,
  RenderRenderOptions,
  RenderText,
} from '../types';
import { getTextByTemplate } from '../utils';

type RenderOptions = {
  displayTemplate?: string;
};

const { formItems } = getRenders({
  formItems: {
    displayTemplate: {
      label: 'Display template',
      name: ['options', 'displayTemplate'],
      children: React.createElement(Input),
    },
  },
});

export const renderRenderOptions: RenderRenderOptions = () => {
  return [formItems.displayTemplate({ key: 'displayTemplate' })];
};

export const renderField: RenderField = ({ col }) => {
  return React.createElement(JsonInput);
};

export const renderColumn: RenderColumn<RenderOptions> = (
  { value },
  { col }
) => {
  let j: string = '';
  if (value) {
    try {
      if (col.options?.displayTemplate) {
        j = getTextByTemplate(value, col.options.displayTemplate);
      } else {
        j = JSON.stringify(value);
      }
    } catch (error) {
      j = 'not-json';
    }
  }
  return React.createElement('div', { children: j });
};

export const renderText: RenderText<RenderOptions> = ({ value }, { col }) => {
  let j: string = '';
  if (value) {
    try {
      if (col.options?.displayTemplate) {
        j = getTextByTemplate(value, col.options.displayTemplate);
      } else {
        j = JSON.stringify(value);
      }
    } catch (error) {
      j = 'not-json';
    }
  }
  return j;
};
