import { Button, Divider, Space, Typography } from 'antd';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReplyBox from './ReplyBox';
import ConversationMesasge from './ConversationMessage';
import { gql, useQuery, useSubscription } from '@apollo/client';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { Spinner } from '../Spinner';
import { sortBy } from 'lodash';
import useCurrentUser from '../../modules/app/useCurrentUser';
import useMarkReadConversation from './hooks/useMarkReadConversation';

export const CONVERSATION_MESSAGE = gql`
  query ConversationMessage(
    $where: ConversationMessage_bool_exp = {}
    $limit: Int = 10
    $offset: Int = 0
    $order_by: [ConversationMessage_order_by!] = {}
  ) {
    ConversationMessage(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      id
      createdOn
      conversationId
      content
      attachments
      updatedOn
      userId
      User {
        id
        name
        email
        avatar
        phoneNumber
      }
    }
  }
`;

const CONVERSATION_MESSAGE_STREAM = gql`
  subscription ConversationMessageStream(
    $createdOn: timestamptz = ""
    $conversationId: uuid
  ) {
    ConversationMessage_stream(
      batch_size: 10
      cursor: { initial_value: { createdOn: $createdOn }, ordering: ASC }
      where: { conversationId: { _eq: $conversationId } }
    ) {
      id
      createdOn
      conversationId
      content
      attachments
      updatedOn
      userId
      User {
        id
        name
        email
        avatar
        phoneNumber
      }
    }
  }
`;

/**
 * Anchor messages list: https://css-tricks.com/books/greatest-css-tricks/pin-scrolling-to-bottom/
 */
export default function Conversation(props: { conversationId: string }) {
  const { conversationId } = props;
  const thisTime = useMemo(() => {
    return new Date().toISOString();
  }, []);

  const loadMoreState = useRef({
    offset: 0,
    loading: false,
  });
  const [isMoreLoading, setLoadMore] = useState(false);
  const [isNoMore, setNoMore] = useState(false);
  const [shouldShowLoadMore, setShowLoadMore] = useState(false);
  const { data: currentUser } = useCurrentUser();
  const markReadAll = useMarkReadConversation(conversationId);

  useEffect(() => {
    const t = setInterval(markReadAll, 2500);
    return () => clearInterval(t);
  }, []);

  // Query for old message
  const { data, loading, error, fetchMore, previousData } = useQuery(
    CONVERSATION_MESSAGE,
    {
      variables: {
        where: {
          conversationId: {
            _eq: conversationId,
          },
          createdOn: {
            _lt: thisTime,
          },
        },
        offset: 0,
        limit: 10,
        order_by: {
          createdOn: 'desc',
        },
      },
    }
  );
  const isFirstLoad = data && !previousData;

  useEffect(() => {
    if (!isFirstLoad) {
      return;
    }
    const no = data.ConversationMessage.length;
    if (no >= 10) {
      loadMoreState.current.offset = no;
      setShowLoadMore(true);
    }
  }, [isFirstLoad]);

  /**
   * Subscribe to next message and write to cache
   */
  useSubscription(CONVERSATION_MESSAGE_STREAM, {
    skip: !thisTime,
    variables: {
      createdOn: thisTime,
      conversationId,
    },
    onData: async ({ client, data }) => {
      const where = {
        conversationId: {
          _eq: conversationId,
        },
        createdOn: {
          _lt: thisTime,
        },
      };
      client.cache.writeQuery({
        query: CONVERSATION_MESSAGE,
        variables: { where },
        data: {
          ConversationMessage: data.data?.ConversationMessage_stream,
        },
      });
    },
  });

  const divRef = useRef<HTMLDivElement>(null);
  // scroll to bottom on first load
  useEffect(() => {
    divRef.current && divRef.current.scrollTo(0, divRef.current.scrollHeight);
  }, [divRef.current]);

  const loadMore = useCallback(() => {
    const shouldLoadMore =
      loadMoreState.current.offset && !loadMoreState.current.loading;
    if (!shouldLoadMore) {
      return;
    }
    setLoadMore(true);
    fetchMore({
      variables: {
        offset: loadMoreState.current.offset,
      },
    })
      .then((result) => {
        const no = (result.data?.ConversationMessage || []).length;
        loadMoreState.current.offset += no;
        if (no < 10) {
          setNoMore(true);
        }
      })
      .finally(() => {
        setLoadMore(false);
      });
  }, []);

  const messages = useMemo(() => {
    const items = [...(data?.ConversationMessage || [])].map((item) => ({
      ...item,
      name: item.User?.name || item.User?.email || 'Unknown',
      avatar: item.User?.avatar,
      createdOn: moment(item.createdOn),
    }));
    return sortBy(items, 'createdOn');
  }, [data]);

  return (
    <>
      <Helmet>
        <style type="text/css">{`
          #scroller * {
            overflow-anchor: none;
          }

          #anchor {
            overflow-anchor: auto;
            height: 1px;
          }
        `}</style>
      </Helmet>
      <Space
        direction="vertical"
        split={<Divider style={{ marginTop: 10, marginBottom: 10 }} />}
        style={{ width: '100%' }}
      >
        <div
          ref={divRef}
          id="scroller"
          style={{
            width: '100%',
            maxHeight: '50vh',
            minHeight: 200,
            overflowY: 'scroll',
          }}
        >
          {shouldShowLoadMore && (
            <div
              style={{
                textAlign: 'center',
                height: 30,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {isMoreLoading && <Spinner />}
              {!isNoMore && !isMoreLoading && (
                <Button size="small" type="primary" onClick={() => loadMore()}>
                  Load More
                </Button>
              )}
              {isNoMore && (
                <Typography.Text type="secondary">
                  <small>No more messages</small>
                </Typography.Text>
              )}
            </div>
          )}
          {messages.map((item: any) => {
            return (
              <ConversationMesasge
                key={item.id}
                isOwner={item.userId === currentUser?.id}
                createdOn={item.createdOn}
                name={item.name}
                avatar={item.avatar}
                content={item.content}
              />
            );
          })}
          <div id="anchor"></div>
        </div>
        <ReplyBox conversationId={conversationId} />
      </Space>
    </>
  );
}
