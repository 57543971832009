import { TableField } from '../hasura/types';
import { TableResource } from './getResources';

/**
 * Define the supported data types
 */
export enum InputType { // should add string values to use object.values correctly
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  RADIO = 'RADIO',
  DATE = 'DATE',
  SELECT = 'SELECT',
  ADDRESS = 'ADDRESS',
  AREA = 'AREA',
  PHOTO = 'PHOTO',
  RELATED_ARRAY = 'RELATED_ARRAY',
  JSON = 'JSON',
  OBJECT = 'OBJECT',
  RICH_TEXT = 'RICH_TEXT',
}

/**
 * Col settings type is generate col settings with col options
 */
export type ColSettings<RenderOptions = any> = {
  type: InputType;

  // base title
  title?: string;

  // default hide this column
  defaultHideColumn?: boolean; // hide column by default

  // display order
  sortOrder?: number;

  // searchable by list
  searchable?: boolean;
  // searchOptions?: {
  //   type: 'OPTIONS' | 'TEXT_MATCH';
  // };

  // bulk edit settings
  bulkEditable?: boolean;
  // bulkEditOptions?: {
  //   type: 'OPTIONS'
  // }

  // render options, to use by all renderer
  options?: RenderOptions;
};

/**
 * The group of col (data type settings) and field (in spec)
 */
export type ColField<RenderOptions = any> = {
  col: ColSettings<RenderOptions>; // undefined if no setting set yet
  field: TableField;
};

/**
 * Table settings
 */
export type TableSettings = {
  title?: string;
  cols: Record<string, ColSettings>; // this current cannot detect type of col options
};

/**
 * To render options for other renders: field, column, text
 */
export type RenderRenderOptions = (
  field: TableField,
  tableResource: TableResource
) => React.ReactNode;

/**
 * Render RenderOptions in view mode
 */
export type RenderRenderOptionsViewMode<RenderOptions = any> = (
  renderOptions: RenderOptions,
  field: TableField,
  tableResource: TableResource
) => React.ReactNode;

/**
 * Render form item field children
 */
export type RenderField<RenderOptions = any> = (
  colField: ColField<RenderOptions>,
  tableResource: TableResource
) => React.ReactNode;

/**
 * Render bulk edit input
 */
export type RenderBulkEdit<RenderOptions = any> = (
  listProps: {
    onSubmit: (values: any) => Promise<void>;
    selected: string[];
  },
  colField: ColField<RenderOptions>,
  tableResource: TableResource
) => React.ReactNode;

/**
 * For table column render
 */
export type RenderColumn<RenderOptions = any> = (
  cell: { value: any; record: any; index: number },
  colField: ColField<RenderOptions>,
  tableResource: TableResource
) => React.ReactNode;

/**
 * For export data
 */
export type RenderText<RenderOptions = any> = (
  cell: { value: any; record: any; index: number },
  colField: ColField<RenderOptions>,
  tableResource: TableResource
) => string | Promise<string>;
