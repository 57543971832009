import { Image } from 'antd';
import React from 'react';
import { getMediaUrl, MediaFile } from '../utils/media';

export type CellPhotoProps = {
  value: MediaFile[] | MediaFile;
};

/**
 * Display a cell in column in the list
 */
export default function CellPhoto(props: CellPhotoProps) {
  const { value } = props;
  const photos = value ? (Array.isArray(value) ? value : [value]) : [];

  return (
    <div>
      {photos.map((photo, index) => {
        const url = getMediaUrl(photo);
        if (!url) {
          return null;
        }
        return (
          <div
            key={index}
            style={{ margin: '0 8px 8px 0', display: 'inline-block' }}
          >
            <Image
              src={url}
              style={{
                width: 80,
                height: 80,
                objectFit: 'contain',
              }}
            />
          </div>
        );
      })}
    </div>
  );
}
