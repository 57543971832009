import { isFunction } from 'lodash';
import { useCallback, useState } from 'react';

/**
 * Modify from: https://github.com/chakra-ui/chakra-ui/blob/next/packages/hooks/src/use-controllable.ts
 * Some other libs: https://medium.com/quick-code/writing-ui-components-with-optionally-controllable-state-86e396a6f1ec
 */
export interface UseControllableStateProps<T> {
  /**
   * The value to used in controlled mode
   */
  value?: T;
  /**
   * The initial value to be used, in uncontrolled mode
   */
  defaultValue?: T | (() => T);
  /**
   * The callback fired when the value changes
   */
  onChange?: (value: T) => void;
  /**
   * The function that determines if the state should be updated
   */
  shouldUpdate?: (prev: T, next: T) => boolean;
}

/**
 * React hook for using controlling component state.
 * @param props
 */
export default function useControllableState<T>(
  props: UseControllableStateProps<T>
) {
  const {
    value: valueProp,
    defaultValue,
    onChange: onChangeProp,
    shouldUpdate: shouldUpdateProp = (prev, next) => prev !== next,
  } = props;

  const [valueState, setValue] = useState(defaultValue as T);
  const isControlled = valueProp !== undefined;
  const value = isControlled ? (valueProp as T) : valueState;

  const updateValue = useCallback(
    (next: React.SetStateAction<T>) => {
      const nextValue = isFunction(next) ? next(value) : next;

      if (!shouldUpdateProp(value, nextValue)) {
        return;
      }

      if (!isControlled) {
        setValue(nextValue);
      }

      onChangeProp && onChangeProp(nextValue);
    },
    [isControlled, onChangeProp, value, shouldUpdateProp]
  );

  return [value, updateValue] as [T, React.Dispatch<React.SetStateAction<T>>];
}
