import { gql, useLazyQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { MediaFile } from '../../utils/media';
import useTokens from './useTokens';

const GET_USER = gql`
  query GetUser($id: uuid! = "") {
    User_by_pk(id: $id) {
      avatar
      createdOn
      email
      id
      isEmailVerified
      isPhoneNumberVerified
      name
      phoneNumber
      updatedOn
      role
    }
  }
`;

/**
 * To get info of logged in user
 */
export default function useCurrentUser() {
  const {
    data: { tokens },
  } = useTokens();
  const [getUser, getUserResult] = useLazyQuery(GET_USER);

  type Result = Pick<typeof getUserResult, 'called' | 'loading' | 'error'> & {
    data: {
      avatar?: MediaFile;
      email: string;
      id: string;
      name?: string;
      phoneNumber?: string;
      role: string;
    };
  };

  useEffect(() => {
    if (tokens) {
      getUser({
        variables: { id: tokens.userId },
      });
    }
  }, [tokens]);

  return useMemo(() => {
    return {
      error: getUserResult.error,
      data: getUserResult.data?.User_by_pk,
      loading: getUserResult.loading,
      called: getUserResult.called,
    };
  }, [getUserResult]) as Result;
}
