import React from 'react';
import { Tag, Tooltip } from 'antd';
import { useTableLoad } from '../modules/row-data/queryHooks';
import {
  getCommonRecordDisplay,
  getTextByTemplate,
} from '../modules/row-data/utils';

export type CellSelectProps = {
  value: string;
  table: string;
  displayTemplate?: string;
  titleTemplate?: string;
  type?: 'TAG' | 'CODE'; // type -> style -> to show
  colors?: Record<string, string>;
};

/**
 * Display a cell in column in the list
 */
export default function CellSelect(props: CellSelectProps) {
  const { table, value, displayTemplate, titleTemplate, type, colors } = props;
  const { data } = useTableLoad(table, {
    variables: {
      pk: value,
    },
    skip: !value,
  });
  let content = 'cannot-parsed';
  let title = '';
  if (!data) {
    content = '...';
  } else {
    if (displayTemplate) {
      content = getTextByTemplate(data, displayTemplate);
      if (titleTemplate) {
        title = getTextByTemplate(data, titleTemplate);
      }
    } else {
      content = getCommonRecordDisplay(data) || null;
    }
  }

  if (type === 'TAG') {
    const color = colors && colors[value];
    return (
      <Tooltip title={title}>
        <Tag {...(color ? { color } : {})}>{content}</Tag>
      </Tooltip>
    );
  }

  return <>{content}</>;
}
