import { PageProps } from 'gatsby';
import React from 'react';
import { TableRowActionProps } from '../components/TableList';
import { TableResource } from '../modules/row-data/getResources';
import { MenuItem } from '../utils/common';

export default abstract class SiteBase {
  public abstract appId: string; // to define env key, ex: local token key
  public abstract defaultAvatar?: string;

  /**
   * Main site title
   */
  public abstract siteTitle: string;

  /**
   * Will define menus for each roles
   */
  public abstract menuRoles: string[];

  public abstract api: {
    url: string;
  };

  public abstract graphql: {
    url: string;
    ws: string;
  };

  public abstract media: {
    // S3 = get S3 signed url
    uploadUrl: string | 'S3';
    baseUrl?: string;
  };

  public abstract google: {
    mapApi: string;
  };

  getMediaUrl(
    media: { filename: string; bucketName?: string },
    size: string = 'original'
  ): string {
    if (Array.isArray(media)) {
      return this.getMediaUrl(media[0]);
    }
    return `${this.media.baseUrl}/${size}/${media.filename}`;
  }

  getKeyDataType() {
    return {};
  }

  /**
   * ColFields by key
   * Use for save any KeyData values
   */
  getColFields(key: string): TableResource['colFields'] | undefined | void {}

  getCustomPages(): Record<string, React.FunctionComponent<PageProps>> {
    return {};
  }

  getMainMenus(
    menus: MenuItem[],
    context: { user: { id: string; role: string } }
  ) {
    // add extra menus for admin role
    if (context.user?.role === 'ADMIN') {
      return [
        {
          title: 'Setup',
          path: '/setup',
          subMenus: [
            {
              title: 'Data Settings',
              path: '/data-settings/KeyData',
            },
            {
              title: 'Menus',
              path: '/setup/menus',
            },
            {
              title: 'Global Variables',
              path: '/setup/variables',
            },
          ],
        },
        ...menus,
      ];
    }

    return menus;
  }

  /**
   * Get row action hook
   */
  getRowActions(
    rowActions: TableRowActionProps,
    context: { table: string }
  ): TableRowActionProps {
    if (context.table === 'User') {
      rowActions.push((record) => {
        return {
          children: 'Change Password',
          key: 'detail',
          href: `/change-password?userId=${record.id}`,
          type: 'default',
        };
      });
    }
    return rowActions;
  }

  getExtraHeaderRight(): React.ReactNode {
    return null;
  }
}
