/* istanbul ignore file */
import { TypePolicies } from '@apollo/client';
import { uniqBy } from 'lodash';

const typePolicies: TypePolicies = {
  KeyData: {
    keyFields: ['key'],
  },
  Query: {
    fields: {
      ConversationMessage: {
        keyArgs: ['where'],
        merge(existing = [], incoming) {
          return uniqBy([...existing, ...incoming], '__ref');
        },
      },
    },
  },
  Subscription: {
    fields: {
      ConversationMessage_stream: {
        keyArgs: ['where'],
        merge(existing = [], incoming) {
          return incoming;
        },
      },
      ConversationUnreadCount: {
        merge(existing = [], incoming) {
          return incoming;
        },
      },
    },
  },
};

export default typePolicies;
