import React, { useMemo } from 'react';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import { InputBaseProps } from '../../../utils/common';
import dayjs, { Dayjs } from 'dayjs';

export type DateInputProps = {
  format?: 'date' | 'datetime';
};

export const DateInput: React.FC<InputBaseProps<Dayjs> & DateInputProps> = ({
  value,
  onChange,
  format,
}) => {
  const change = (value: any) => {
    onChange && onChange(value);
  };

  const datePickerProps: any = useMemo(() => {
    if (format === 'datetime') {
      return { showTime: { format: 'HH:mm' } };
    }
    return {};
  }, [format]);

  const pickerValue = useMemo(() => {
    return value ? dayjs(value) : null;
  }, [value]);

  // if (format === 'datetime') {
  //   datePickerProps.showTime = { format: 'HH:mm' };
  //   datePickerProps.value = value ? moment(value) : null;
  // } else {
  //   datePickerProps.value = value ? moment(value) : null;
  // }
  return (
    <DatePicker {...datePickerProps} value={pickerValue} onChange={change} />
  );
};
