import React, { useCallback } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {
  UploadFile,
  UploadChangeParam,
  UploadProps,
} from 'antd/lib/upload/interface';
import { useState } from 'react';
import { InputBaseProps } from '../../../utils/common';
import { getBase64, getMediaUrl, MediaFile } from '../../../utils/media';
import config from '../../../modules/dynamic-config';
import { Modal, Upload } from 'antd';
import upload from '../../../utils/upload';

export type PhotoInputProps = InputBaseProps<MediaFile[]> & {
  previewSize?: string; // @@@ on dev
};

export default function PhotoInput(props: PhotoInputProps) {
  const { value, onChange } = props;
  const [fileList, setFileList] = useState<UploadFile[]>(
    (value ? (Array.isArray(value) ? value : [value]) : []).map(
      (mediaFile, index) => {
        return {
          uid: index.toString(),
          name: mediaFile.filename,
          status: 'done',
          url: getMediaUrl(mediaFile),
          response: mediaFile,
        };
      }
    ) as UploadFile[]
  );
  const [preview, setPreview] = useState({
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
  });

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleChange = (info: UploadChangeParam) => {
    setFileList(info.fileList);
    const allMediaFiles = info.fileList
      .map(({ response }: any) => response)
      .filter(Boolean);

    if (allMediaFiles.length === info.fileList.length) {
      onChange && onChange(allMediaFiles);
    }
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as File);
    }
    setPreview({
      previewImage: (file.url || file.preview) as string,
      previewVisible: true,
      previewTitle: file.name,
    });
  };

  const customRequest = useCallback<
    Required<UploadProps<any>>['customRequest']
  >(async (options) => {
    upload(options.file as File, {
      uploadUrl: config.media.uploadUrl,
      onProgress: (_, e) => {
        options.onProgress?.(e);
      },
      onSuccess: (r) => {
        options.onSuccess?.(r);
      },
    });
  }, []);

  return (
    <>
      <Upload
        customRequest={customRequest}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
      >
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>
      <Modal
        visible={preview.previewVisible}
        title={preview.previewTitle}
        footer={null}
        onCancel={() =>
          setPreview((prev) => ({ ...prev, previewVisible: false }))
        }
      >
        <img
          alt="example"
          style={{ width: '100%' }}
          src={preview.previewImage}
        />
      </Modal>
    </>
  );
}
