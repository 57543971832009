import Checkbox, { CheckboxProps } from 'antd/lib/checkbox/Checkbox';
import React from 'react';
import useJsonInput from '../../../hooks/useJsonInput';
import { InputBaseProps } from '../../../utils/common';
import VSpace from '../../VSpace';

export type CheckboxInputProps = InputBaseProps<boolean> &
  Pick<CheckboxProps, 'children' | 'disabled'>;

export default function CheckboxInput(props: CheckboxInputProps) {
  const { value, onChange, readOnly, ...rest } = props;
  return (
    <Checkbox
      checked={!!value}
      onChange={(e) => onChange && onChange(e.target.checked)}
      {...rest}
    />
  );
}

type CheckGroupOption = {
  value: string;
  label?: string;
  key?: string;
};

export type CheckGroupInputProps = InputBaseProps<string> & {
  options: CheckGroupOption[];
};

export function CheckGroupInput(props: CheckGroupInputProps) {
  const { options } = props;
  const { getInputProps } = useJsonInput(props);
  return (
    <VSpace>
      {options.map((option, index) => {
        return (
          <CheckboxInput
            key={option.key || index}
            {...getInputProps(option.value)}
            children={option.label}
          />
        );
      })}
    </VSpace>
  );
}
