import React from 'react';
import { Select } from 'antd';
import { useTableSearch } from '../../../modules/row-data/queryHooks';
import { useAppContext } from '../../../modules/app';

export type SelectInputProps = {
  table: string;
  getLabel?: (record: any) => string;
  value?: string | number;
  onChange?: (value: string | number | null) => void;
  placeholder?: string;
  disabled?: boolean;
};

export default function SelectInput(props: SelectInputProps) {
  const {
    value,
    onChange = () => null,
    table,
    getLabel,
    placeholder,
    disabled,
  } = props;
  const pk = useAppContext().specs[table].pk;
  const { data } = useTableSearch(table, {
    variables: {
      limit: 999,
    },
    fetchPolicy: 'cache-and-network',
  });

  const items = data?.records || [];
  return (
    <Select
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabled={disabled}
    >
      {(items || []).map((item: any) => (
        <Select.Option key={item[pk]} value={item[pk]}>
          {getLabel ? getLabel(item) : item.name}
        </Select.Option>
      ))}
    </Select>
  );
}
