import { FormItemProps } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { get, sortBy, startCase } from 'lodash';
import React from 'react';
import { getInputConfig } from '.';
import { TableResource } from './getResources';
import { InputType } from './types';

/**
 * Table resource helper
 * Get relation field by internal field name
 */
export const getRelationField = (
  fieldName: string,
  tableResource: TableResource
) => {
  const relationField = Object.values(tableResource.spec.fields).find(
    (item) => {
      return item.key === fieldName && item.key !== tableResource.spec.pk;
    }
  );
  return relationField;
};

/**
 * ColFields to items props of QuickForm
 */
export const getFormFields = (
  tableResource: TableResource,
  permission: 'create' | 'update' = 'create'
): FormItemProps[] => {
  return Object.values(tableResource.colFields)
    .filter((item) => {
      const { permissions } = item.field;
      return permissions[permission];
    })
    .map((item) => {
      const key = item.field.name;
      const { title, type } = item.col;

      const { renderField } = getInputConfig(type);
      return {
        name: key,
        label: React.createElement('strong', {}, title || startCase(key)),
        children: renderField?.(item, tableResource),
      };
    });
};

/**
 * ColFields to Antd table columns props
 */
export const getTableColumns = <T = any>(
  tableResource: TableResource
): ColumnType<T>[] => {
  // make sured it sorted correctly
  const sortFiltered = sortBy(
    Object.values(tableResource.colFields),
    (item) => item.col.sortOrder
  );
  return sortFiltered.map((item) => {
    const key = item.field.name;
    const { title, type } = item.col;
    const { renderColumn } = getInputConfig(type);
    return {
      dataIndex: key,
      title: title || startCase(key),
      render: (value, record, index) => {
        return renderColumn?.({ value, record, index }, item, tableResource);
      },
    };
  });
};

/**
 * Get export data { rows, headers } by records data and colFields config
 * The result can be passed to exportExcel
 */
export async function getExportData<T extends unknown[]>(
  records: T[],
  tableResource: TableResource
) {
  const sortedColFields = sortBy(
    Object.values(tableResource.colFields),
    (item) => item.col.sortOrder
  );

  const headers: string[] = sortedColFields.map((colField) => {
    return colField.col.title || startCase(colField.field.name);
  });

  const rows = await Promise.all(
    records.map(async (record, index) => {
      const entries = await Promise.all(
        sortedColFields.map(async (colField) => {
          const name = colField.field.name;
          const colType = colField.col.type as InputType;
          const { renderText } = getInputConfig(colType);
          const value = record?.[name as keyof T];
          const cValue =
            (await renderText?.(
              { value, record, index },
              colField,
              tableResource
            )) || value;
          return [name, cValue];
        })
      );
      return Object.fromEntries(entries);
    })
  );

  return { rows, headers };
}

/**
 * Parse display record by template defined
 */
export function getTextByTemplate(record: any, template: string) {
  const vars = template.match(/{.*?}/g); // -> [{var1}, {var2}...]
  let result = template;
  (vars || []).forEach((v) => {
    const p = v.replace(/{|\}/g, '');
    result = result.replaceAll(v, get(record, p));
  });
  return result;
}

/**
 * Testing solution for display a record beside
 *  - lib of functions to format for data-settings
 *  - template token to format for data-settings
 * Get display of common record by pick common fields: title, name, email, phone, code
 */
export function getCommonRecordDisplay(record: any = {}) {
  const { id, code, name, title, comment, value, email, phone } = record || {};
  const sub = [email, phone].filter(Boolean)[0];
  const main = [code, name, title, comment, value].filter(Boolean)[0];
  return [main, sub].filter(Boolean).join('-') || id;
}

/**
 * This helper split object into { groupKey: groupedObject... }
 * Using to split submit values into relation fields and own fields values
 */
export function splitValues(values: any, getKey: (key: string) => string) {
  return Object.keys(values).reduce((prev, key) => {
    const value = values[key];
    const groupKey = getKey(key);
    prev[groupKey] = { ...prev[groupKey], [key]: value };
    return prev;
  }, {} as any);
}
