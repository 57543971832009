import React from 'react';
import MapInput from '../../../components/inputs/MapInput';
import { InputType, RenderColumn, RenderField, RenderText } from '../types';

export const renderField: RenderField = ({ col }) => {
  return React.createElement(MapInput);
};

export const renderColumn: RenderColumn = ({ value }) => {
  return value?.title;
};

export const renderText = renderColumn as RenderText;
