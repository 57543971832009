import React, { useEffect, useMemo } from 'react';
import { Alert, Card, Col, Divider, Input, Layout, Row, Space } from 'antd';
import { Helmet } from 'react-helmet';
import { QuickForm } from '../components/QuickForm';
import { PageProps } from 'gatsby';
import getRenders from '../utils/getRenders';
import { UserOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import useBeFn from '../modules/app/useBeFn';
import useTokens from '../modules/app/useTokens';
import config from '../modules/dynamic-config';

export type LoginFormValues = {
  email: string;
  password: string;
};

/**
 * Renderer
 */
const { formItems, buttons } = getRenders({
  formItems: {
    email: {
      name: 'email',
      rules: [
        {
          required: true,
          type: 'email',
          message: 'Email required!',
        },
      ],
      children: <Input placeholder="Email" size="large" />,
    },
    password: {
      name: 'password',
      rules: [{ required: true, message: 'Password required!' }],
      children: <Input.Password size="large" placeholder="Password" />,
    },
  },
  buttons: {
    submit: {
      htmlType: 'submit',
      children: 'Submit',
      type: 'primary',
      block: true,
      size: 'large',
    },
  },
});

export default function Login(props: PageProps) {
  const [{ loading: loginLoading, data: tokens, error: loginError }, login] =
    useBeFn('user/login');
  const { setTokens } = useTokens();

  useEffect(() => {
    if (tokens) {
      setTokens(tokens);
    }
  }, [tokens]);

  const errorMessage = useMemo(() => {
    if (!loginError) {
      return;
    }
    return loginError.code === 'INCORRECT_PASSWORD'
      ? loginError.message
      : 'Login error';
  }, [loginError]);

  const submit = (value: LoginFormValues) => {
    return login({
      email: value.email,
      password: value.password,
    });
  };

  return (
    <Layout
      style={{
        minHeight: '100vh',
        backgroundColor: '#ececec',
      }}
    >
      <Helmet title="Admin Login" />
      <Layout.Content
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Card
          style={{ width: 360 }}
          title={
            <Space>
              <UserOutlined /> {config.siteTitle} Admin Login
            </Space>
          }
        >
          {errorMessage && (
            <Alert
              showIcon
              message={errorMessage}
              type="error"
              closable
              style={{ marginBottom: 24 }}
            />
          )}
          <QuickForm<LoginFormValues>
            labelCol={{ span: 0 }}
            wrapperCol={{ span: 24 }}
            layout="vertical"
            onFinish={submit}
          >
            {formItems.email()}
            {formItems.password()}
            <Divider />
            {buttons.submit({
              loading: loginLoading,
            })}
          </QuickForm>
        </Card>
      </Layout.Content>
      <Layout.Footer style={{ background: 'none' }}>
        <Row justify="center">
          <Text type="secondary">
            <small>xAdmin version 1.0</small>
          </Text>
        </Row>
      </Layout.Footer>
    </Layout>
  );
}
