import { Typography } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';

/**
 * Current render options view mode by JSON stringify value
 */
export function renderRenderOptionsViewModeJSON(options: any) {
  let jsonStr: string;
  try {
    if (isEmpty(options)) {
      throw null;
    }
    jsonStr = JSON.stringify(options);
    return React.createElement(Typography.Paragraph, {
      children: React.createElement('blockquote', {
        key: 'json',
        children: jsonStr,
      }),
    });
  } catch {
    return null;
  }
}
