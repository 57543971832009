import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Layout, Space, Spin } from 'antd';

export function PageLoading() {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <Layout
      style={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Space direction="vertical" align="center">
        <Spin indicator={antIcon} />
      </Space>
    </Layout>
  );
}

export function Spinner() {
  const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;
  return <Spin indicator={antIcon} />;
}
