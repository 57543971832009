import { makeVar } from '../../hooks/useReactiveVar';
import { MenuItem } from '../../utils/common';
import generateVarHook from '../../utils/generateVarHook';
import { IntroTable } from '../hasura/types';
import { DataResources } from '../row-data/getResources';
import { TableSettings } from '../row-data/types';

export type AppVar = {
  // googleMapApi?: string;
  // // Stripe config
  // stripe: {
  //   merchantIdentifier: string;
  //   urlScheme: string;
  //   setUrlSchemeOnAndroid: boolean;
  // };

  menus: MenuItem[];
  tables: Record<string, TableSettings>;
  specs: Record<string, IntroTable>;
  resources: DataResources;
};

export const appVar = makeVar<AppVar>({} as AppVar);
const useAppVar = generateVarHook(appVar);
export default useAppVar;
