import { gql, useMutation } from '@apollo/client';
import { Button, Col, Input, Row } from 'antd';
import { trim } from 'lodash';
import React, { useCallback, useState } from 'react';

const INSERT_CONVERSATION_MESSAGE = gql`
  mutation InsertConversationMessage(
    $object: ConversationMessage_insert_input = {}
  ) {
    insert_ConversationMessage_one(object: $object) {
      content
      attachments
    }
  }
`;

export type ReplyBoxProps = {
  conversationId: string;
};

export default function ReplyBox(props: ReplyBoxProps) {
  const { conversationId } = props;
  const [value, setValue] = useState('');
  const [createMutation, createResult] = useMutation(
    INSERT_CONVERSATION_MESSAGE
  );

  const sendContent = useCallback(
    (inputContent: string) => {
      const content = trim(inputContent);
      if (!content) {
        return;
      }
      createMutation({
        variables: {
          object: {
            content,
            conversationId,
          },
        },
      });
      setValue('');
    },
    [conversationId]
  );

  return (
    <Row gutter={5}>
      <Col flex="auto">
        <Input.TextArea
          placeholder="Send a message"
          value={value}
          rows={1}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              sendContent(value);
            }
          }}
        >
          {value}
        </Input.TextArea>
      </Col>
      <Col>
        <Button
          type="primary"
          loading={createResult.loading}
          onClick={() => sendContent(value)}
        >
          Send
        </Button>
      </Col>
    </Row>
  );
}
