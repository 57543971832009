import { Button } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import React, { ComponentProps, Key } from 'react';

/**
 * Elements generator
 */
const toElements = <P extends Object, O extends Record<string, P>>(
  obj: O,
  E: React.FunctionComponent<P>
) => {
  const entries = Object.entries(obj || {}).map(([key, props]) => {
    return [
      key,
      (extraProps?: Partial<P>) =>
        React.createElement(E, { ...props, ...extraProps }),
    ];
  });
  return Object.fromEntries(entries);
};

/**
 * Add supported Components to getRenders
 */
const getRenderMapComponents = {
  buttons: Button,
  formItems: FormItem,
};

type MapType = typeof getRenderMapComponents;

/**
 * Define type of data to returns
 */
export default function getRenders<
  D extends {
    [K in keyof MapType]?: Record<string, ComponentProps<MapType[K]>>;
  }
>(
  data: D
): {
  [K in keyof MapType]: {
    [KK in keyof D[K]]: (
      props?: ComponentProps<MapType[K]> & { key?: Key }
    ) => React.ReactNode;
  };
} {
  const entries = Object.entries(getRenderMapComponents).map(([key, C]) => {
    return [key, toElements((data as any)[key] || {}, C as any)];
  });
  return Object.fromEntries(entries);
}
