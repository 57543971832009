import React, { useEffect, useState } from 'react';
import { InputBaseProps } from '../../../utils/common';
import {
  getControlButtonElement,
  getCoordinatesPolygon,
  getDrawing,
  getPolygonCoordinates,
} from '../../../utils/googleMap';
import GoogleMapReact from 'google-map-react';
import config from '../../../modules/dynamic-config';

export type Geometry = {
  type: string; // polygon, line
  coordinates: any[];
};

export type AreaInputProps = InputBaseProps<Geometry>;
export default function AreaInput(props: AreaInputProps) {
  const { value, onChange } = props;
  const [loadedMap, setLoadedMap] = useState<any>();

  useEffect(() => {
    const { mapAPI, mapInstance } = loadedMap || {};
    if (!mapAPI || !mapInstance) {
      return; // there some error while init map
    }

    // Show existing polygon
    let myPolygon: any;
    if (value) {
      myPolygon = getCoordinatesPolygon(mapAPI, value.coordinates[0]);
      myPolygon.setMap(loadedMap.mapInstance);
      loadedMap.mapInstance.fitBounds(myPolygon.getBounds());
    }

    const drawing = getDrawing(mapAPI);
    mapAPI.event.addListener(
      drawing,
      'polygoncomplete',
      function (polygon: any) {
        const coordinates = getPolygonCoordinates(
          polygon.getPaths().getArray()[0]
        );
        if (myPolygon) {
          myPolygon.setMap(null);
        }
        onChange &&
          onChange({
            type: 'Polygon',
            coordinates: [coordinates],
          });
      }
    );

    const overlays: any = [];
    mapAPI.event.addListener(drawing, 'overlaycomplete', function (e: any) {
      overlays.push(e);
    });
    drawing.setMap(mapInstance);

    // Clear map button
    const clearBtn = getControlButtonElement({
      label: 'Clear Map',
      onClick: () => {
        overlays.forEach(({ overlay }: any) => overlay.setMap(null));
        onChange && onChange(null);
      },
    });
    mapInstance.controls[mapAPI.ControlPosition.TOP_LEFT].push(clearBtn);

    return () => {
      mapAPI.event.clearInstanceListeners(drawing);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedMap]);

  return (
    <>
      <div style={{ height: 400 }}>
        <GoogleMapReact
          center={{ lat: 10.7735994, lng: 106.6944173 }}
          zoom={9}
          bootstrapURLKeys={{
            key: config.google.mapApi,
            libraries: ['places', 'geometry', 'drawing'],
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map: mapInstance, maps: mapAPI }) => {
            mapInstance &&
              mapAPI &&
              setLoadedMap({
                mapAPI,
                mapInstance,
              });
          }}
        />
      </div>
    </>
  );
}
