import { noop, omit, pick } from 'lodash';
import { MediaFile } from './media';
import { fetchBe } from '../modules/app/useBeFn';

type UploadOptions = {
  uploadUrl: string;
  onSuccess?: (mediaFile: MediaFile) => void;
  onError?: (msg?: string) => void;
  onProgress?: (percent: number, e: ProgressEvent) => void;
};

/***
 * ON DEV: chunk upload
 * Refs:
 *  https://github.com/mortzmortz/use-xhr/blob/develop/lib/useXhr.ts
 *  https://uppy.io/examples/dashboard/
 *  https://pqina.nl/filepond
 *  https://www.dropzone.dev/
 */
export default async function upload(file: File, options: UploadOptions): void {
  const {
    uploadUrl,
    onError = noop,
    onSuccess = noop,
    onProgress = noop,
  } = options;

  const isUploadToS3 = uploadUrl === 'S3';
  const xhr = new XMLHttpRequest();
  let signedResult: any;

  if (isUploadToS3) {
    signedResult = await fetchBe('file/getS3UploadUrl', {
      filename: file.name,
    });
    xhr.open('PUT', signedResult.url, true);
    xhr.setRequestHeader('Content-Type', file.type);
  } else {
    xhr.open('POST', uploadUrl, true);
  }

  xhr.onerror = () => {
    onError();
  };

  xhr.upload.onprogress = (e) => {
    if (e.lengthComputable) {
      const percentComplete = (e.loaded / e.total) * 100;
      onProgress(percentComplete, e);
    }
  };

  xhr.onload = () => {
    if ([200, 201].includes(xhr.status)) {
      if (signedResult) {
        onSuccess(pick(signedResult, ['filename', 'bucketName']));
      } else {
        try {
          const resp = JSON.parse(xhr.response);
          onSuccess(resp);
        } catch {
          onError('Cannot parse response json');
        }
      }
    } else {
      onError(`Invalid response status ${xhr.status}`);
    }
  };

  if (isUploadToS3) {
    xhr.send(file);
  } else {
    const formData = new FormData();
    formData.append('file', file);
    xhr.send(formData);
  }
}
