import { MinusOutlined } from '@ant-design/icons';
import { Button, Col, Form, List, Modal, Row } from 'antd';
import { isEqual, pick, pickBy, update } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useAppContext } from '../../../modules/app';
import { getFormFields } from '../../../modules/row-data/utils';
import { InputBaseProps } from '../../../utils/common';
import { QuickForm } from '../../QuickForm';

export type RelatedArrayInputProps = InputBaseProps<any> & {
  table: string;
  foreignKey: string;
};

export default function RelatedArrayInput(props: RelatedArrayInputProps) {
  // special with appContext for relation resource
  const { resources } = useAppContext();
  const { value, onChange, table, foreignKey } = props;
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const relationResource = resources[table];

  // colFields, omit foreignKey field
  const relationFields = useMemo(() => {
    const { colFields, ownFields } = relationResource;
    return pickBy(colFields, (value, key) => {
      return value.field.name !== foreignKey && ownFields[key];
    });
  }, [relationResource, foreignKey]);

  const fields = getFormFields({ colFields: relationFields } as any, 'update');

  const dataSource: any = useMemo(() => {
    const relationFieldKeys = Object.keys(relationFields);
    return (value || []).map((item: any) => pick(item, relationFieldKeys));
  }, [value, relationFields]);

  const onCreate = (values: any) => {
    onChange && onChange([...dataSource, values]);
  };
  const remove = (removeItem: any) => {
    const nextData = dataSource.filter((item: any) => {
      return !isEqual(item, removeItem);
    });
    onChange?.(nextData);
  };
  return (
    <>
      <List
        header={
          <Row align="middle">
            <Col flex={1}>Header</Col>
            <Col>
              <Button type="primary" onClick={() => setVisible(true)}>
                Add User
              </Button>
            </Col>
          </Row>
        }
        dataSource={dataSource}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                size="small"
                shape="circle"
                icon={<MinusOutlined />}
                onClick={() => remove(item)}
              />,
            ]}
          >
            {JSON.stringify(item)}
          </List.Item>
        )}
      />
      <Modal
        visible={visible}
        title="Create a new collection"
        okText="Create"
        cancelText="Cancel"
        onCancel={() => setVisible(false)}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreate(values);
              setVisible(false);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <QuickForm form={form} fields={fields} />
      </Modal>
    </>
  );
}
