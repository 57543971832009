import { Typography } from 'antd';
import Input from 'antd/es/input/Input';
import { camelCase, startCase } from 'lodash';
import React from 'react';
import CellSelect, { CellSelectProps } from '../../../cells/CellSelect';
import JsonInput from '../../../components/inputs/JsonInput';
import SelectInput from '../../../components/inputs/SelectInput';
import getRenders from '../../../utils/getRenders';
import { client } from '../../apollo';
import {
  RenderBulkEdit,
  RenderColumn,
  RenderField,
  RenderRenderOptions,
  RenderText,
} from '../types';
import {
  getCommonRecordDisplay,
  getRelationField,
  getTextByTemplate,
} from '../utils';
import { appVar } from './../../app/useAppVar';

type RenderOptions = {
  table: string;
  displayTemplate?: string;
  column?: Pick<
    CellSelectProps,
    'type' | 'titleTemplate' | 'displayTemplate' | 'colors'
  >;
};

const { formItems } = getRenders({
  formItems: {
    // force relation table
    table: {
      label: 'Force relation table',
      name: ['options', 'table'],
      children: React.createElement(Input),
    },
    displayTemplate: {
      label: 'Display template',
      name: ['options', 'displayTemplate'],
      children: React.createElement(Input),
    },
    cell: {
      name: ['options', 'cell'],
      children: React.createElement(JsonInput),
    },
  },
});

export const renderRenderOptions: RenderRenderOptions = (
  field,
  tableResource
) => {
  const relationField = getRelationField(field.name, tableResource);
  return [
    React.createElement(Typography.Paragraph, {
      key: 'text',
      children:
        'Relation: ' +
        (relationField ? relationField.to : 'Not found relation'),
    }),
    formItems.table({ key: 'table' }),
    formItems.displayTemplate({ key: 'displayTemplate' }),
    formItems.cell({ key: 'cell' }),
  ];
};

export const renderField: RenderField<RenderOptions> = (
  { col, field },
  tableResource
) => {
  let table = col.options?.table;
  if (!table) {
    const relationField = getRelationField(field.name, tableResource);
    if (relationField) {
      table = relationField.to as string;
    }
  }
  if (!table) {
    return `Not found relation field of field ${field.name}`;
  }
  return React.createElement(SelectInput, {
    table,
    getLabel: getCommonRecordDisplay,
    placeholder: col.title,
  });
};

export const renderBulkEdit: RenderBulkEdit<RenderOptions> = (
  { onSubmit, selected },
  { field, col },
  tableResource
) => {
  let table = col.options?.table;
  if (!table) {
    const relationField = getRelationField(field.name, tableResource);
    if (relationField) {
      table = relationField.to as string;
    }
  }
  if (!table) {
    return `Not found relation field of field ${field.name}`;
  }
  const title = col.title || startCase(field.name);
  return React.createElement(SelectInput, {
    table,
    getLabel: getCommonRecordDisplay,
    placeholder: title,
    value: `Set ${title}...`,
    disabled: !selected.length,
    onChange: (value) => {
      onSubmit({
        [field.name]: value,
      });
    },
  });
};

export const renderColumn: RenderColumn<RenderOptions> = (
  { value },
  { field, col },
  tableResource
) => {
  let table = col.options?.table;
  if (!table) {
    const relationField = getRelationField(field.name, tableResource);
    if (relationField) {
      table = relationField.to as string;
    }
  }
  if (!table) {
    return `Not found relation field of field ${field.name}`;
  }
  return React.createElement(CellSelect, {
    table,
    value,
    displayTemplate: col.options?.displayTemplate,
    ...col.options?.column,
  });
};

export const renderText: RenderText<RenderOptions> = async (
  { value },
  { field, col },
  tableResource
) => {
  let table = col.options?.table;
  if (!table) {
    const relationField = getRelationField(field.name, tableResource);
    if (relationField) {
      table = relationField.to as string;
    }
  }
  if (!table) {
    return `Not found relation field of field ${field.name}`;
  }
  const doc = appVar().resources[table].docs.load;
  const result = await client.query({
    query: doc,
    variables: { pk: value },
    fetchPolicy: 'cache-first',
  });
  const data = result.data[`${table}_by_pk`];
  let content = 'cannot-parsed';
  const displayTemplate = col.options?.displayTemplate;
  if (displayTemplate) {
    content = getTextByTemplate(data, displayTemplate);
  } else {
    content = getCommonRecordDisplay(data) || null;
  }
  return content;
};
