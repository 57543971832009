exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-[table]-create-tsx": () => import("./../../../src/pages/[table]/create.tsx" /* webpackChunkName: "component---src-pages-[table]-create-tsx" */),
  "component---src-pages-[table]-edit-[id]-tsx": () => import("./../../../src/pages/[table]/edit/[id].tsx" /* webpackChunkName: "component---src-pages-[table]-edit-[id]-tsx" */),
  "component---src-pages-[table]-list-tsx": () => import("./../../../src/pages/[table]/list.tsx" /* webpackChunkName: "component---src-pages-[table]-list-tsx" */),
  "component---src-pages-change-password-tsx": () => import("./../../../src/pages/change-password.tsx" /* webpackChunkName: "component---src-pages-change-password-tsx" */),
  "component---src-pages-data-settings-[name]-tsx": () => import("./../../../src/pages/data-settings/[name].tsx" /* webpackChunkName: "component---src-pages-data-settings-[name]-tsx" */),
  "component---src-pages-data-settings-sortable-list-tsx": () => import("./../../../src/pages/data-settings/SortableList.tsx" /* webpackChunkName: "component---src-pages-data-settings-sortable-list-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-page-[name]-tsx": () => import("./../../../src/pages/page/[name].tsx" /* webpackChunkName: "component---src-pages-page-[name]-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-settings-[name]-tsx": () => import("./../../../src/pages/settings/[name].tsx" /* webpackChunkName: "component---src-pages-settings-[name]-tsx" */),
  "component---src-pages-setup-menus-tsx": () => import("./../../../src/pages/setup/menus.tsx" /* webpackChunkName: "component---src-pages-setup-menus-tsx" */),
  "component---src-pages-setup-variables-tsx": () => import("./../../../src/pages/setup/variables.tsx" /* webpackChunkName: "component---src-pages-setup-variables-tsx" */),
  "component---src-pages-verify-code-action-tsx": () => import("./../../../src/pages/verify-code-action.tsx" /* webpackChunkName: "component---src-pages-verify-code-action-tsx" */)
}

