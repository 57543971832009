import {
  MutationHookOptions,
  MutationOptions,
  MutationTuple,
  QueryHookOptions,
  useApolloClient,
  useMutation,
  useQuery,
} from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';
import { usePromiseResult } from '../../hooks/usePromiseResult';
import { useAppContext } from '../app';
import { splitValues } from './utils';

/**
 * Query load helper
 */
export function useTableLoad(
  tableName: string,
  options?: QueryHookOptions<any, { pk: string }>
) {
  const resource = useAppContext().resources[tableName];
  if (!resource) {
    throw new Error(`Not found resource for ${tableName}`);
  }
  const result = useQuery(resource.docs.load, options);
  return {
    ...result,
    data: result.data?.[`${tableName}_by_pk`],
  };
}

/**
 * Query search helper
 * ON DEV:
 *  - should care about refetch and update query
 *  - the tableName change, but the data still old value
 */
export function useTableSearch(
  tableName: string,
  options?: QueryHookOptions<
    any,
    {
      where?: any;
      limit?: number;
      offset?: number;
      orderBy?: any;
    }
  >
) {
  const { docs } = useAppContext().resources[tableName];
  const result = useQuery(docs.search, options);
  return {
    ...result,
    data: {
      records: result.data?.[`${tableName}`],
      count: result.data?.[`${tableName}_aggregate`]?.aggregate?.count,
    },
  };
}

/**
 * Mutation create helper
 */
export function useTableCreate<
  TData extends Record<string, any>,
  TVariables = { data: any }
>(
  tableName: string,
  options?: MutationHookOptions<TData, TVariables>
): MutationTuple<TData, TVariables> {
  const { docs, relationFields } = useAppContext().resources[tableName];
  const [mutate, mutateResult] = useMutation(docs.create, options);

  const create = useCallback<typeof mutate>(
    (options) => {
      const data = (options?.variables as any).data;
      const { values, relationValues } = splitValues(data, (key) => {
        return relationFields[key] ? 'relationValues' : 'values';
      });
      const relationData = Object.keys(relationValues || {}).reduce(
        (prev, key) => {
          prev[key] = {
            data: relationValues[key],
          };
          return prev;
        },
        {} as any
      );

      return mutate({
        ...options,
        variables: {
          data: {
            ...values,
            ...relationData,
          },
        } as any,
      });
    },
    [mutate]
  );

  return [
    create,
    useMemo(() => {
      return {
        ...mutateResult,
        data: mutateResult.data?.[`insert_${tableName}_one`],
      };
    }, [mutateResult]),
  ];
}

/**
 * Mutation update helper
 * @@@ will refactor
 */
export function useTableUpdate<
  TData extends Record<string, any>,
  TVariables = { pk: string; data: any }
>(
  tableName: string,
  options?: MutationHookOptions<TData, TVariables>
): MutationTuple<TData, TVariables> {
  const client = useApolloClient();
  const { resources } = useAppContext();
  const { docs, relationFields } = resources[tableName];
  const [mutate, mutateResult] = useMutation(docs.update, options);
  const [updateRelation, updateRelationResult] = usePromiseResult(
    async (mutations: MutationOptions[]) => {
      return Promise.all(
        mutations.map((options) => {
          return client.mutate(options);
        })
      );
    }
  );
  const [updateResult, setUpdateResult] = useState<any>({
    loading: false,
    called: false,
    data: undefined,
    error: undefined,
  });

  // The update should handle relation
  const update = useCallback(
    async (options) => {
      const { pk: pkValue, data } = options?.variables as any;
      const { values, relationValues } = splitValues(data, (key) => {
        return relationFields[key] ? 'relationValues' : 'values';
      });
      const relationMutations: MutationOptions[] = [];
      Object.keys(relationValues || {}).forEach((key) => {
        const relationField = relationFields[key];

        // Must handle ARRAY relations update this way
        if (relationField?.type === 'ARRAY' && relationField.to) {
          const relationTableResource = resources[relationField.to];
          if (!relationTableResource) {
            console.error(
              `There no permission to update on resource ${relationField.to}`
            );
            return;
          }

          // Add foreignKey value to replaceData
          const replaceData = relationValues[key].map((item: any) => {
            return {
              ...item,
              [relationField.foreignKey as string]: pkValue,
            };
          });

          relationMutations.push({
            mutation: relationTableResource.docs.replace,
            variables: {
              where: {
                [relationTableResource.spec.pk]: {
                  _eq: pkValue,
                },
              },
              data: replaceData,
            },
          });
        }
      });

      setUpdateResult({
        loading: true,
        called: true,
        error: undefined,
      });

      const updateRelationResult = await updateRelation(relationMutations);
      if (updateRelationResult.error) {
        setUpdateResult({
          loading: false,
          error: updateRelationResult.error,
        });
        return;
      }
      const result = await mutate({
        ...options,
        variables: {
          ...(options?.variables as any),
          data: values,
        },
      });
      if (result.errors) {
        setUpdateResult({
          loading: false,
          error: result.errors,
        });
      }

      setUpdateResult({
        ...result,
        loading: false,
      });
      return result;
    },
    [mutate]
  );

  return [update, updateResult] as MutationTuple<TData, TVariables>;
}

export function useTableUpdateMany<
  TData extends Record<string, any>,
  TVariables = { where: any; data: any }
>(
  tableName: string,
  options?: MutationHookOptions<TData, TVariables>
): MutationTuple<TData, TVariables> {
  const { docs } = useAppContext().resources[tableName];
  const result = useMutation(docs.updateMany, options);
  return useMemo(() => {
    return [
      result[0],
      {
        ...result[1],
        data: result[1].data?.[`update_${tableName}`]?.returning,
      },
    ];
  }, result);
}

/**
 * Mutation delete helper
 */
export function useTableDelete<
  TData extends Record<string, any>,
  TVariables = { pk: string }
>(
  tableName: string,
  options?: MutationHookOptions<TData, TVariables>
): MutationTuple<TData, TVariables> {
  const { docs } = useAppContext().resources[tableName];
  const result = useMutation(docs.delete, options);
  return [
    result[0],
    {
      ...result[1],
      data: result[1].data?.[`delete_${tableName}_by_pk`],
    },
  ];
}
