import React from 'react';
import AreaInput from '../../../components/inputs/AreaInput';
import { InputType, RenderColumn, RenderField } from '../types';

export const renderField: RenderField<InputType.AREA> = ({ col }) => {
  return React.createElement(AreaInput);
};

export const renderColumn: RenderColumn<InputType.AREA> = ({ value }) => {
  return 'AreaCol';
};
