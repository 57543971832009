import { gql, useQuery, useSubscription } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { makeVar } from '../../../hooks/useReactiveVar';
import useReactiveVar from '../../../hooks/useReactiveVar';
import { isEqual, sum } from 'lodash';

const COUNT_SUBSCRIPTION = gql`
  subscription ConversationUnreadCount {
    ConversationUnreadCount(limit: 100) {
      count
      userId
      conversationId
    }
  }
`;

const unreadVar = makeVar<Record<string, number>>({});

export default function useSubscribeToConversationsUnreadCount() {
  const result = useSubscription(COUNT_SUBSCRIPTION);

  useEffect(() => {
    const entries = (result.data?.ConversationUnreadCount || []).map(
      (item: any) => {
        return [item.conversationId, item.count];
      }
    );
    unreadVar(Object.fromEntries(entries));
  }, [result.data]);

  const value = useReactiveVar(unreadVar);
  return value;
}

export function useConversationUnreadCount(conversationId: string) {
  const a = useReactiveVar(unreadVar);
  return a?.[conversationId] || 0;
}

export function useTotalUnread() {
  const a = useReactiveVar(unreadVar);
  return sum(Object.entries(a).map(([k, v]) => v));
}
