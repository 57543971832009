import { message } from 'antd';
import { useCallback, useState } from 'react';
import { getAuthorizationHeaders, TokenProps } from '../../utils/user';
import config, { ExtraBeType } from '../dynamic-config';

export type BeFnType = {
  // Auth API
  'user/signIn': {
    input: any;
    result: any;
  };
  'user/isUserExists': {
    input: { email?: string; phoneNumber?: string };
    result: { exists: boolean };
  };
  'user/createUser': {
    input: {
      email?: string;
      phoneNumber?: string;
      password: string;
      name?: string;
    };
    result: TokenProps;
  };
  'user/createGuest': {
    input: any;
    result: any;
  };
  'user/login': {
    input: {
      email?: string;
      phoneNumber?: string;
      password: string;
    };
    result: TokenProps;
  };
  'user/updatePassword': {
    input: { currentPassword: string; password: string; userId?: string };
    result: { success: boolean; error?: { code: string } };
  };
  'verifyCode/send': {
    input: {
      verifyEmail?: string;
      verifyPhoneNumber?: string;
      template: string;
    };
    result: { success: boolean };
  };
  'verifyCode/resetPassword': {
    input: {
      code: string;
      hash: string;
      password: string;
    };
    result: { success: boolean };
  };

  // File
  'file/getS3UploadUrl': {
    input: { filename: string };
    result: {
      url: string;
      filename: string;
      bucketName: string;
      expiresIn: number;
    };
  };

  // Stripe API
  'stripe/getStripeKey': {
    input: any;
    result: {
      publishableKey: string;
    };
  };
  'stripe/getCards': {
    input: any;
    result: {
      id: string;
      brand: string;
      last4: string;
      name: string;
      fingerprint: string;
    }[];
  };
  'stripe/removeCard': {
    input: { id: string };
    result: { success: boolean };
  };
  'stripe/createSetupIntent': {
    input: {};
    result: { clientSecret: string };
  };
} & ExtraBeType;

export type FnResult<A extends keyof BeFnType> = {
  loading?: boolean;
  data?: BeFnType[A]['result'];
  error?: any;
};

export async function fetchBe<A extends keyof BeFnType>(
  action: A,
  input?: BeFnType[A]['input'],
  options?: Pick<RequestInit, 'headers'>
): Promise<BeFnType[A]['result']> {
  // define outsite cause custom page use createPrivatePage error
  // still investigating
  const apiUrl = config.api.url;
  // const apiUrl = 'https://0ef1-116-102-235-14.ap.ngrok.io';

  const response = await fetch(`${apiUrl}/${action}`, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      ...(await getAuthorizationHeaders()),
      ...options?.headers,
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    ...(input
      ? {
          body: JSON.stringify(input || {}), // body data type must match "Content-Type" header
        }
      : {}),
  });

  const result = await response.json(); // parses JSON response into native JavaScript objects
  if ([200, 201].includes(response.status)) {
    return result;
  }
  throw result;
}

/**
 * Hook for quick call api
 */
export default function useBeFn<A extends keyof BeFnType>(action: A) {
  const [result, setResult] = useState<FnResult<A>>({});

  const trigger = useCallback(
    async (
      input?: BeFnType[A]['input'],
      options?: Pick<RequestInit, 'headers'>
    ) => {
      console.info(`Trigger useBeFn ${action}`);
      setResult({ loading: true });
      const nextResult: FnResult<A> = { loading: false };

      try {
        nextResult.data = await fetchBe(action, input, options);
      } catch (error) {
        nextResult.error = error;
        if ((error as any)?.message) {
          message.error((error as any).message);
        }
      }

      setResult(nextResult);
      return nextResult;
    },
    []
  );

  return [result, trigger] as [
    FnResult<A>,
    (
      input?: BeFnType[A]['input'],
      options?: Pick<RequestInit, 'headers'>
    ) => Promise<FnResult<A>>
  ];
}
