import { UserOutlined } from '@ant-design/icons';
import { Avatar, Space, Typography } from 'antd';
import React, { useMemo } from 'react';
import { MediaFile, getMediaUrl } from '../../utils/media';
import { Moment } from 'moment';
import { isEmpty } from 'lodash';

type ConversationMesasgeProps = {
  isOwner?: boolean;
  createdOn: Moment;
  name?: string;
  avatar?: MediaFile;
  content?: string;
  attachments?: MediaFile[];
};

export default function ConversationMesasge(props: ConversationMesasgeProps) {
  const { createdOn, name, avatar, content } = props;
  const isRight = !!props.isOwner;

  const avatarUrl = useMemo(() => {
    if (isEmpty(avatar)) return;
    return getMediaUrl(avatar, 'small');
  }, [avatar]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: isRight ? 'flex-end' : 'flex-start',
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      <Space
        align="start"
        style={{
          maxWidth: '85%',
          flexDirection: isRight ? 'row-reverse' : 'row',
        }}
      >
        <Avatar
          src={avatarUrl}
          icon={<UserOutlined />}
          style={{ marginTop: 5 }}
        />
        <Space direction="vertical" size={0} align={isRight ? 'end' : 'start'}>
          <Typography.Text type="secondary">
            <small style={{ fontWeight: 500, paddingLeft: 5, paddingRight: 5 }}>
              {name || '--'}, {createdOn.format('hh:mm A')}
            </small>
          </Typography.Text>
          <div
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 5,
              paddingBottom: 5,
              borderRadius: 8,
              fontSize: '0.8rem',
              boxShadow:
                '0 1px 2px 0 rgba(0,0,0,.03), 0 1px 6px -1px rgba(0,0,0,.02), 0 2px 4px 0 rgba(0,0,0,.02)',
              backgroundColor: isRight ? '#E6F4FF' : '#FAFAFA',
            }}
          >
            {content}
          </div>
        </Space>
      </Space>
    </div>
  );
}
