import React from 'react';
import { WrapPageElementBrowserArgs, WrapPageElementNodeArgs } from 'gatsby';
import 'antd/dist/reset.css';

export default function WrapPageElement({
  element,
  props,
}: WrapPageElementBrowserArgs | WrapPageElementNodeArgs) {
  return <>{element}</>;
}
