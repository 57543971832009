import React from 'react';
import { Space, SpaceProps } from 'antd';

export default function VSpace(props: SpaceProps) {
  return (
    <Space
      direction="vertical"
      {...props}
      style={{ width: '100%', ...props.style }}
    />
  );
}
