import React from 'react';
import {
  Button,
  ButtonProps,
  Form,
  FormItemProps,
  FormProps,
  Space,
} from 'antd';

export type QuickFormProps<T = any> = {
  fields?: FormItemProps[];
  actionButtons?: ButtonProps[];
  children?: React.ReactNode;
} & Pick<
  FormProps<T>,
  | 'component'
  | 'initialValues'
  | 'layout'
  | 'labelCol'
  | 'wrapperCol'
  | 'name'
  | 'onFinish'
  | 'onFinishFailed'
  | 'onValuesChange'
  | 'onFieldsChange'
  | 'form'
  | 'style'
>;

export const QuickForm = <T extends any>(props: QuickFormProps<T>) => {
  const { fields = [], actionButtons = [], children, ...rest } = props;
  return (
    <Form<T> {...rest}>
      {children}
      {fields.map((field) => {
        return <Form.Item key={field.name as string} {...field} />;
      })}
      {!!actionButtons.length && (
        <Form.Item
          // horizontal should have space for better align render
          label={props.layout === 'horizontal' ? ' ' : ''}
          colon={false}
        >
          <Space>
            {actionButtons.map((btnProps, index) => {
              return <Button {...btnProps} key={index.toString()} />;
            })}
          </Space>
        </Form.Item>
      )}
    </Form>
  );
};
