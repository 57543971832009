import { find, forEach, keyBy } from 'lodash';
import { IntroTable, TableField } from './types';

const sampleData = {
  data: {
    db_get_constraints: [
      {
        data: {
          tableName: 'User',
          columnName: 'id',
          constraint_type: 'PRIMARY KEY',
          foreignTableName: null,
          foreignColumnName: null,
        },
        key: 'User_pkey',
      },
      {
        data: {
          tableName: 'User',
          columnName: 'email',
          constraint_type: 'UNIQUE',
          foreignTableName: null,
          foreignColumnName: null,
        },
        key: 'User_email_key',
      },
      {
        data: {
          tableName: 'User',
          columnName: 'phoneNumber',
          constraint_type: 'UNIQUE',
          foreignTableName: null,
          foreignColumnName: null,
        },
        key: 'User_phoneNumber_key',
      },
      {
        data: {
          tableName: 'VerifyCode',
          columnName: 'id',
          constraint_type: 'PRIMARY KEY',
          foreignTableName: null,
          foreignColumnName: null,
        },
        key: 'VerifyCode_pkey',
      },
      {
        data: {
          tableName: 'ForRent',
          columnName: 'id',
          constraint_type: 'PRIMARY KEY',
          foreignTableName: null,
          foreignColumnName: null,
        },
        key: 'ForRent_pkey',
      },
      {
        data: {
          tableName: 'Location',
          columnName: 'id',
          constraint_type: 'PRIMARY KEY',
          foreignTableName: null,
          foreignColumnName: null,
        },
        key: 'Location_pkey',
      },
      {
        data: {
          tableName: 'spatial_ref_sys',
          columnName: 'srid',
          constraint_type: 'PRIMARY KEY',
          foreignTableName: null,
          foreignColumnName: null,
        },
        key: 'spatial_ref_sys_pkey',
      },
      {
        data: {
          tableName: 'VerifyCode',
          columnName: 'code',
          constraint_type: 'UNIQUE',
          foreignTableName: null,
          foreignColumnName: null,
        },
        key: 'VerifyCode_code_verifyEmail_verifyPhoneNumber_key',
      },
      {
        data: {
          tableName: 'VerifyCode',
          columnName: 'verifyEmail',
          constraint_type: 'UNIQUE',
          foreignTableName: null,
          foreignColumnName: null,
        },
        key: 'VerifyCode_code_verifyEmail_verifyPhoneNumber_key',
      },
      {
        data: {
          tableName: 'VerifyCode',
          columnName: 'verifyPhoneNumber',
          constraint_type: 'UNIQUE',
          foreignTableName: null,
          foreignColumnName: null,
        },
        key: 'VerifyCode_code_verifyEmail_verifyPhoneNumber_key',
      },
      {
        data: {
          tableName: 'Location',
          columnName: 'userId',
          constraint_type: 'FOREIGN KEY',
          foreignTableName: 'User',
          foreignColumnName: 'id',
        },
        key: 'Location_userId_fkey',
      },
      {
        data: {
          tableName: 'PayoutStatus',
          columnName: 'value',
          constraint_type: 'PRIMARY KEY',
          foreignTableName: null,
          foreignColumnName: null,
        },
        key: 'PayoutStatus_pkey',
      },
      {
        data: {
          tableName: 'Payout',
          columnName: 'id',
          constraint_type: 'PRIMARY KEY',
          foreignTableName: null,
          foreignColumnName: null,
        },
        key: 'Payout_pkey',
      },
      {
        data: {
          tableName: 'Payout',
          columnName: 'status',
          constraint_type: 'FOREIGN KEY',
          foreignTableName: 'PayoutStatus',
          foreignColumnName: 'value',
        },
        key: 'Payout_status_fkey',
      },
      {
        data: {
          tableName: 'Booking',
          columnName: 'payoutId',
          constraint_type: 'FOREIGN KEY',
          foreignTableName: 'Payout',
          foreignColumnName: 'id',
        },
        key: 'Booking_payoutId_fkey',
      },
      {
        data: {
          tableName: 'Brand',
          columnName: 'id',
          constraint_type: 'PRIMARY KEY',
          foreignTableName: null,
          foreignColumnName: null,
        },
        key: 'Brand_pkey',
      },
      {
        data: {
          tableName: 'UserSetting',
          columnName: 'userId',
          constraint_type: 'FOREIGN KEY',
          foreignTableName: 'User',
          foreignColumnName: 'id',
        },
        key: 'UserSetting_userId_fkey',
      },
      {
        data: {
          tableName: 'ForRent',
          columnName: 'locationId',
          constraint_type: 'FOREIGN KEY',
          foreignTableName: 'Location',
          foreignColumnName: 'id',
        },
        key: 'ForRent_locationId_fkey',
      },
      {
        data: {
          tableName: 'Booking',
          columnName: 'brandId',
          constraint_type: 'FOREIGN KEY',
          foreignTableName: 'Brand',
          foreignColumnName: 'id',
        },
        key: 'Booking_brandId_fkey',
      },
      {
        data: {
          tableName: 'BookingStatus',
          columnName: 'value',
          constraint_type: 'PRIMARY KEY',
          foreignTableName: null,
          foreignColumnName: null,
        },
        key: 'BookingStatus_pkey',
      },
      {
        data: {
          tableName: 'PaymentStatus',
          columnName: 'value',
          constraint_type: 'PRIMARY KEY',
          foreignTableName: null,
          foreignColumnName: null,
        },
        key: 'PaymentStatus_pkey',
      },
      {
        data: {
          tableName: 'Booking',
          columnName: 'id',
          constraint_type: 'PRIMARY KEY',
          foreignTableName: null,
          foreignColumnName: null,
        },
        key: 'Booking_pkey',
      },
      {
        data: {
          tableName: 'Booking',
          columnName: 'status',
          constraint_type: 'FOREIGN KEY',
          foreignTableName: 'BookingStatus',
          foreignColumnName: 'value',
        },
        key: 'Booking_status_fkey',
      },
      {
        data: {
          tableName: 'Booking',
          columnName: 'paymentStatus',
          constraint_type: 'FOREIGN KEY',
          foreignTableName: 'PaymentStatus',
          foreignColumnName: 'value',
        },
        key: 'Booking_paymentStatus_fkey',
      },
      {
        data: {
          tableName: 'Booking',
          columnName: 'userId',
          constraint_type: 'FOREIGN KEY',
          foreignTableName: 'User',
          foreignColumnName: 'id',
        },
        key: 'Booking_userId_fkey',
      },
      {
        data: {
          tableName: 'KeyData',
          columnName: 'key',
          constraint_type: 'PRIMARY KEY',
          foreignTableName: null,
          foreignColumnName: null,
        },
        key: 'KeyData_pkey',
      },
      {
        data: {
          tableName: 'UserRealm',
          columnName: 'value',
          constraint_type: 'PRIMARY KEY',
          foreignTableName: null,
          foreignColumnName: null,
        },
        key: 'UserRealm_pkey',
      },
      {
        data: {
          tableName: 'User',
          columnName: 'realm',
          constraint_type: 'FOREIGN KEY',
          foreignTableName: 'UserRealm',
          foreignColumnName: 'value',
        },
        key: 'User_realm_fkey',
      },
      {
        data: {
          tableName: 'PaymentType',
          columnName: 'value',
          constraint_type: 'PRIMARY KEY',
          foreignTableName: null,
          foreignColumnName: null,
        },
        key: 'PaymentType_pkey',
      },
      {
        data: {
          tableName: 'Booking',
          columnName: 'paymentType',
          constraint_type: 'FOREIGN KEY',
          foreignTableName: 'PaymentType',
          foreignColumnName: 'value',
        },
        key: 'Booking_paymentType_fkey',
      },
      {
        data: {
          tableName: 'Place',
          columnName: 'id',
          constraint_type: 'PRIMARY KEY',
          foreignTableName: null,
          foreignColumnName: null,
        },
        key: 'Place_pkey',
      },
      {
        data: {
          tableName: 'Place',
          columnName: 'userId',
          constraint_type: 'FOREIGN KEY',
          foreignTableName: 'User',
          foreignColumnName: 'id',
        },
        key: 'Place_userId_fkey',
      },
      {
        data: {
          tableName: 'Tags',
          columnName: 'id',
          constraint_type: 'PRIMARY KEY',
          foreignTableName: null,
          foreignColumnName: null,
        },
        key: 'PlaceTags_pkey',
      },
      {
        data: {
          tableName: 'Tags',
          columnName: 'userId',
          constraint_type: 'FOREIGN KEY',
          foreignTableName: 'User',
          foreignColumnName: 'id',
        },
        key: 'PlaceTags_userId_fkey',
      },
      {
        data: {
          tableName: 'PlaceTags',
          columnName: 'placeId',
          constraint_type: 'PRIMARY KEY',
          foreignTableName: null,
          foreignColumnName: null,
        },
        key: 'PlaceTags_pkey1',
      },
      {
        data: {
          tableName: 'PlaceTags',
          columnName: 'tagsId',
          constraint_type: 'PRIMARY KEY',
          foreignTableName: null,
          foreignColumnName: null,
        },
        key: 'PlaceTags_pkey1',
      },
      {
        data: {
          tableName: 'PlaceTags',
          columnName: 'placeId',
          constraint_type: 'FOREIGN KEY',
          foreignTableName: 'Place',
          foreignColumnName: 'id',
        },
        key: 'PlaceTags_placeId_fkey',
      },
      {
        data: {
          tableName: 'PlaceTags',
          columnName: 'tagsId',
          constraint_type: 'FOREIGN KEY',
          foreignTableName: 'Tags',
          foreignColumnName: 'id',
        },
        key: 'PlaceTags_tagsId_fkey',
      },
      {
        data: {
          tableName: 'UserRole',
          columnName: 'value',
          constraint_type: 'PRIMARY KEY',
          foreignTableName: null,
          foreignColumnName: null,
        },
        key: 'UserRole_pkey',
      },
      {
        data: {
          tableName: 'User',
          columnName: 'role',
          constraint_type: 'FOREIGN KEY',
          foreignTableName: 'UserRole',
          foreignColumnName: 'value',
        },
        key: 'User_role_fkey',
      },
      {
        data: {
          tableName: 'Booking',
          columnName: 'code',
          constraint_type: 'UNIQUE',
          foreignTableName: null,
          foreignColumnName: null,
        },
        key: 'Booking_code_key',
      },
      {
        data: {
          tableName: 'ForRent',
          columnName: 'brandId',
          constraint_type: 'FOREIGN KEY',
          foreignTableName: 'Brand',
          foreignColumnName: 'id',
        },
        key: 'ForRent_brandId_fkey',
      },
      {
        data: {
          tableName: 'Payout',
          columnName: 'userId',
          constraint_type: 'FOREIGN KEY',
          foreignTableName: 'User',
          foreignColumnName: 'id',
        },
        key: 'Payout_userId_fkey',
      },
      {
        data: {
          tableName: 'UserSetting',
          columnName: 'id',
          constraint_type: 'PRIMARY KEY',
          foreignTableName: null,
          foreignColumnName: null,
        },
        key: 'UserSetting_pkey',
      },
      {
        data: {
          tableName: 'UserSetting',
          columnName: 'userId',
          constraint_type: 'UNIQUE',
          foreignTableName: null,
          foreignColumnName: null,
        },
        key: 'UserSetting_userId_key',
      },
      {
        data: {
          tableName: 'Booking',
          columnName: 'forRentId',
          constraint_type: 'FOREIGN KEY',
          foreignTableName: 'ForRent',
          foreignColumnName: 'id',
        },
        key: 'Booking_forRentId_fkey',
      },
      {
        data: {
          tableName: 'Brand',
          columnName: 'userId',
          constraint_type: 'FOREIGN KEY',
          foreignTableName: 'User',
          foreignColumnName: 'id',
        },
        key: 'Brand_userId_fkey',
      },
      {
        data: {
          tableName: 'ForRent',
          columnName: 'userSettingId',
          constraint_type: 'FOREIGN KEY',
          foreignTableName: 'UserSetting',
          foreignColumnName: 'userId',
        },
        key: 'ForRent_userSettingId_fkey',
      },
      {
        data: {
          tableName: 'BookingLog',
          columnName: 'id',
          constraint_type: 'PRIMARY KEY',
          foreignTableName: null,
          foreignColumnName: null,
        },
        key: 'BookingLog_pkey',
      },
      {
        data: {
          tableName: 'BookingLog',
          columnName: 'bookingId',
          constraint_type: 'FOREIGN KEY',
          foreignTableName: 'Booking',
          foreignColumnName: 'id',
        },
        key: 'BookingLog_bookingId_fkey',
      },
      {
        data: {
          tableName: 'Booking',
          columnName: 'payoutStatus',
          constraint_type: 'FOREIGN KEY',
          foreignTableName: 'PayoutStatus',
          foreignColumnName: 'value',
        },
        key: 'Booking_payoutStatus_fkey',
      },
    ],
    db_get_tables: [
      {
        key: 'User',
        data: [
          {
            udt_name: 'uuid',
            column_name: 'id',
            is_nullable: 'NO',
          },
          {
            udt_name: 'timestamptz',
            column_name: 'createdOn',
            is_nullable: 'NO',
          },
          {
            udt_name: 'timestamptz',
            column_name: 'updatedOn',
            is_nullable: 'NO',
          },
          {
            udt_name: 'text',
            column_name: 'name',
            is_nullable: 'YES',
          },
          {
            udt_name: 'text',
            column_name: 'password',
            is_nullable: 'YES',
          },
          {
            udt_name: 'jsonb',
            column_name: 'avatar',
            is_nullable: 'YES',
          },
          {
            udt_name: 'text',
            column_name: 'email',
            is_nullable: 'YES',
          },
          {
            udt_name: 'text',
            column_name: 'phoneNumber',
            is_nullable: 'YES',
          },
          {
            udt_name: 'bool',
            column_name: 'isEmailVerified',
            is_nullable: 'NO',
          },
          {
            udt_name: 'bool',
            column_name: 'isPhoneNumberVerified',
            is_nullable: 'NO',
          },
          {
            udt_name: 'text',
            column_name: 'realm',
            is_nullable: 'NO',
          },
          {
            udt_name: 'text',
            column_name: 'role',
            is_nullable: 'YES',
          },
        ],
      },
      {
        key: 'Location',
        data: [
          {
            udt_name: 'uuid',
            column_name: 'id',
            is_nullable: 'NO',
          },
          {
            udt_name: 'timestamptz',
            column_name: 'createdOn',
            is_nullable: 'NO',
          },
          {
            udt_name: 'timestamptz',
            column_name: 'updatedOn',
            is_nullable: 'NO',
          },
          {
            udt_name: 'text',
            column_name: 'name',
            is_nullable: 'NO',
          },
          {
            udt_name: 'geometry',
            column_name: 'bound',
            is_nullable: 'YES',
          },
          {
            udt_name: 'uuid',
            column_name: 'userId',
            is_nullable: 'YES',
          },
        ],
      },
      {
        key: 'VerifyCode',
        data: [
          {
            udt_name: 'uuid',
            column_name: 'id',
            is_nullable: 'NO',
          },
          {
            udt_name: 'timestamptz',
            column_name: 'createdOn',
            is_nullable: 'NO',
          },
          {
            udt_name: 'text',
            column_name: 'code',
            is_nullable: 'NO',
          },
          {
            udt_name: 'text',
            column_name: 'verifyEmail',
            is_nullable: 'YES',
          },
          {
            udt_name: 'text',
            column_name: 'verifyPhoneNumber',
            is_nullable: 'YES',
          },
        ],
      },
      {
        key: 'ForRent',
        data: [
          {
            udt_name: 'uuid',
            column_name: 'id',
            is_nullable: 'NO',
          },
          {
            udt_name: 'timestamptz',
            column_name: 'createdOn',
            is_nullable: 'NO',
          },
          {
            udt_name: 'timestamptz',
            column_name: 'updatedOn',
            is_nullable: 'NO',
          },
          {
            udt_name: 'uuid',
            column_name: 'locationId',
            is_nullable: 'YES',
          },
          {
            udt_name: 'jsonb',
            column_name: 'prices',
            is_nullable: 'YES',
          },
          {
            udt_name: 'uuid',
            column_name: 'brandId',
            is_nullable: 'NO',
          },
          {
            udt_name: 'timestamptz',
            column_name: 'dateFrom',
            is_nullable: 'YES',
          },
          {
            udt_name: 'timestamptz',
            column_name: 'dateTo',
            is_nullable: 'YES',
          },
          {
            udt_name: 'int4',
            column_name: 'quantity',
            is_nullable: 'NO',
          },
          {
            udt_name: 'uuid',
            column_name: 'userSettingId',
            is_nullable: 'YES',
          },
        ],
      },
      {
        key: 'spatial_ref_sys',
        data: [
          {
            udt_name: 'int4',
            column_name: 'srid',
            is_nullable: 'NO',
          },
          {
            udt_name: 'varchar',
            column_name: 'auth_name',
            is_nullable: 'YES',
          },
          {
            udt_name: 'int4',
            column_name: 'auth_srid',
            is_nullable: 'YES',
          },
          {
            udt_name: 'varchar',
            column_name: 'srtext',
            is_nullable: 'YES',
          },
          {
            udt_name: 'varchar',
            column_name: 'proj4text',
            is_nullable: 'YES',
          },
        ],
      },
      {
        key: 'Booking',
        data: [
          {
            udt_name: 'uuid',
            column_name: 'id',
            is_nullable: 'NO',
          },
          {
            udt_name: 'timestamptz',
            column_name: 'createdOn',
            is_nullable: 'NO',
          },
          {
            udt_name: 'timestamptz',
            column_name: 'updatedOn',
            is_nullable: 'NO',
          },
          {
            udt_name: 'text',
            column_name: 'code',
            is_nullable: 'YES',
          },
          {
            udt_name: 'timestamptz',
            column_name: 'pickupDate',
            is_nullable: 'NO',
          },
          {
            udt_name: 'timestamptz',
            column_name: 'returnDate',
            is_nullable: 'NO',
          },
          {
            udt_name: 'uuid',
            column_name: 'userId',
            is_nullable: 'NO',
          },
          {
            udt_name: 'uuid',
            column_name: 'forRentId',
            is_nullable: 'YES',
          },
          {
            udt_name: 'jsonb',
            column_name: 'pickupAddress',
            is_nullable: 'NO',
          },
          {
            udt_name: 'jsonb',
            column_name: 'returnAddress',
            is_nullable: 'YES',
          },
          {
            udt_name: 'text',
            column_name: 'specialRequest',
            is_nullable: 'YES',
          },
          {
            udt_name: 'text',
            column_name: 'status',
            is_nullable: 'NO',
          },
          {
            udt_name: 'text',
            column_name: 'paymentStatus',
            is_nullable: 'NO',
          },
          {
            udt_name: 'numeric',
            column_name: 'quantity',
            is_nullable: 'YES',
          },
          {
            udt_name: 'numeric',
            column_name: 'addOnBattery',
            is_nullable: 'YES',
          },
          {
            udt_name: 'bool',
            column_name: 'addOnUpgradeRide',
            is_nullable: 'YES',
          },
          {
            udt_name: 'bool',
            column_name: 'addOnRideInsurance',
            is_nullable: 'YES',
          },
          {
            udt_name: 'jsonb',
            column_name: 'total',
            is_nullable: 'YES',
          },
          {
            udt_name: 'text',
            column_name: 'paymentType',
            is_nullable: 'YES',
          },
          {
            udt_name: 'text',
            column_name: 'paymentMethodId',
            is_nullable: 'YES',
          },
          {
            udt_name: 'uuid',
            column_name: 'payoutId',
            is_nullable: 'YES',
          },
          {
            udt_name: 'uuid',
            column_name: 'brandId',
            is_nullable: 'YES',
          },
          {
            udt_name: 'jsonb',
            column_name: 'extraServices',
            is_nullable: 'YES',
          },
          {
            udt_name: 'text',
            column_name: 'payoutStatus',
            is_nullable: 'YES',
          },
        ],
      },
      {
        key: 'BookingStatus',
        data: [
          {
            udt_name: 'text',
            column_name: 'value',
            is_nullable: 'NO',
          },
          {
            udt_name: 'text',
            column_name: 'comment',
            is_nullable: 'YES',
          },
        ],
      },
      {
        key: 'PaymentStatus',
        data: [
          {
            udt_name: 'text',
            column_name: 'value',
            is_nullable: 'NO',
          },
          {
            udt_name: 'text',
            column_name: 'comment',
            is_nullable: 'NO',
          },
        ],
      },
      {
        key: 'KeyData',
        data: [
          {
            udt_name: 'text',
            column_name: 'key',
            is_nullable: 'NO',
          },
          {
            udt_name: 'jsonb',
            column_name: 'data',
            is_nullable: 'NO',
          },
        ],
      },
      {
        key: 'UserRealm',
        data: [
          {
            udt_name: 'text',
            column_name: 'value',
            is_nullable: 'NO',
          },
          {
            udt_name: 'text',
            column_name: 'comment',
            is_nullable: 'YES',
          },
        ],
      },
      {
        key: 'PaymentType',
        data: [
          {
            udt_name: 'text',
            column_name: 'value',
            is_nullable: 'NO',
          },
          {
            udt_name: 'text',
            column_name: 'comment',
            is_nullable: 'YES',
          },
        ],
      },
      {
        key: 'Tags',
        data: [
          {
            udt_name: 'uuid',
            column_name: 'id',
            is_nullable: 'NO',
          },
          {
            udt_name: 'timestamptz',
            column_name: 'createdOn',
            is_nullable: 'NO',
          },
          {
            udt_name: 'timestamptz',
            column_name: 'updatedOn',
            is_nullable: 'NO',
          },
          {
            udt_name: 'text',
            column_name: 'name',
            is_nullable: 'NO',
          },
          {
            udt_name: 'uuid',
            column_name: 'userId',
            is_nullable: 'NO',
          },
        ],
      },
      {
        key: 'PlaceTags',
        data: [
          {
            udt_name: 'uuid',
            column_name: 'placeId',
            is_nullable: 'NO',
          },
          {
            udt_name: 'uuid',
            column_name: 'tagsId',
            is_nullable: 'NO',
          },
        ],
      },
      {
        key: 'Place',
        data: [
          {
            udt_name: 'uuid',
            column_name: 'id',
            is_nullable: 'NO',
          },
          {
            udt_name: 'timestamptz',
            column_name: 'createdOn',
            is_nullable: 'NO',
          },
          {
            udt_name: 'timestamptz',
            column_name: 'updatedOn',
            is_nullable: 'NO',
          },
          {
            udt_name: 'text',
            column_name: 'title',
            is_nullable: 'NO',
          },
          {
            udt_name: 'text',
            column_name: 'description',
            is_nullable: 'YES',
          },
          {
            udt_name: 'jsonb',
            column_name: 'photos',
            is_nullable: 'YES',
          },
          {
            udt_name: 'uuid',
            column_name: 'userId',
            is_nullable: 'YES',
          },
          {
            udt_name: 'jsonb',
            column_name: 'address',
            is_nullable: 'YES',
          },
          {
            udt_name: 'bool',
            column_name: 'isPublic',
            is_nullable: 'YES',
          },
          {
            udt_name: 'uuid',
            column_name: 'refPlaceId',
            is_nullable: 'YES',
          },
        ],
      },
      {
        key: 'UserRole',
        data: [
          {
            udt_name: 'text',
            column_name: 'value',
            is_nullable: 'NO',
          },
          {
            udt_name: 'text',
            column_name: 'comment',
            is_nullable: 'YES',
          },
        ],
      },
      {
        key: 'PayoutStatus',
        data: [
          {
            udt_name: 'text',
            column_name: 'value',
            is_nullable: 'NO',
          },
          {
            udt_name: 'text',
            column_name: 'coment',
            is_nullable: 'YES',
          },
        ],
      },
      {
        key: 'Brand',
        data: [
          {
            udt_name: 'uuid',
            column_name: 'id',
            is_nullable: 'NO',
          },
          {
            udt_name: 'timestamptz',
            column_name: 'createdOn',
            is_nullable: 'NO',
          },
          {
            udt_name: 'timestamptz',
            column_name: 'updatedOn',
            is_nullable: 'NO',
          },
          {
            udt_name: 'text',
            column_name: 'name',
            is_nullable: 'NO',
          },
          {
            udt_name: 'jsonb',
            column_name: 'photos',
            is_nullable: 'YES',
          },
          {
            udt_name: 'text',
            column_name: 'desc',
            is_nullable: 'YES',
          },
          {
            udt_name: 'jsonb',
            column_name: 'spec',
            is_nullable: 'YES',
          },
          {
            udt_name: 'jsonb',
            column_name: 'prices',
            is_nullable: 'NO',
          },
          {
            udt_name: 'uuid',
            column_name: 'userId',
            is_nullable: 'YES',
          },
          {
            udt_name: 'text',
            column_name: 'code',
            is_nullable: 'YES',
          },
        ],
      },
      {
        key: 'Payout',
        data: [
          {
            udt_name: 'uuid',
            column_name: 'id',
            is_nullable: 'NO',
          },
          {
            udt_name: 'timestamptz',
            column_name: 'createdOn',
            is_nullable: 'NO',
          },
          {
            udt_name: 'timestamptz',
            column_name: 'updatedOn',
            is_nullable: 'NO',
          },
          {
            udt_name: 'text',
            column_name: 'status',
            is_nullable: 'NO',
          },
          {
            udt_name: 'numeric',
            column_name: 'amount',
            is_nullable: 'YES',
          },
          {
            udt_name: 'jsonb',
            column_name: 'attachments',
            is_nullable: 'YES',
          },
          {
            udt_name: 'uuid',
            column_name: 'userId',
            is_nullable: 'NO',
          },
        ],
      },
      {
        key: 'UserSetting',
        data: [
          {
            udt_name: 'uuid',
            column_name: 'id',
            is_nullable: 'NO',
          },
          {
            udt_name: 'uuid',
            column_name: 'userId',
            is_nullable: 'NO',
          },
          {
            udt_name: 'text',
            column_name: 'notifyEmails',
            is_nullable: 'YES',
          },
          {
            udt_name: 'jsonb',
            column_name: 'extraServices',
            is_nullable: 'YES',
          },
        ],
      },
      {
        key: 'BookingLog',
        data: [
          {
            udt_name: 'uuid',
            column_name: 'id',
            is_nullable: 'NO',
          },
          {
            udt_name: 'timestamptz',
            column_name: 'createdOn',
            is_nullable: 'NO',
          },
          {
            udt_name: 'timestamptz',
            column_name: 'updatedOn',
            is_nullable: 'NO',
          },
          {
            udt_name: 'uuid',
            column_name: 'bookingId',
            is_nullable: 'NO',
          },
          {
            udt_name: 'text',
            column_name: 'message',
            is_nullable: 'NO',
          },
          {
            udt_name: 'jsonb',
            column_name: 'data',
            is_nullable: 'YES',
          },
        ],
      },
    ],
  },
};

const sampleIntrospec = {
  __schema: {
    types: [
      {
        __typename: '__Type',
        kind: 'OBJECT',
        name: 'KeyData',
        description: 'columns and relationships of "KeyData"',
        fields: [
          {
            __typename: '__Field',
            name: 'data',
            description: null,
            args: [
              {
                __typename: '__InputValue',
                name: 'path',
                description: 'JSON select path',
                type: {
                  __typename: '__Type',
                  kind: 'SCALAR',
                  name: 'String',
                  ofType: null,
                },
                defaultValue: null,
              },
            ],
            type: {
              __typename: '__Type',
              kind: 'NON_NULL',
              name: null,
              ofType: {
                __typename: '__Type',
                kind: 'SCALAR',
                name: 'jsonb',
                ofType: null,
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
          {
            __typename: '__Field',
            name: 'key',
            description: null,
            args: [],
            type: {
              __typename: '__Type',
              kind: 'NON_NULL',
              name: null,
              ofType: {
                __typename: '__Type',
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        inputFields: [
          {
            __typename: '__Field',
            name: 'data',
            description: null,
            args: [
              {
                __typename: '__InputValue',
                name: 'path',
                description: 'JSON select path',
                type: {
                  __typename: '__Type',
                  kind: 'SCALAR',
                  name: 'String',
                  ofType: null,
                },
                defaultValue: null,
              },
            ],
            type: {
              __typename: '__Type',
              kind: 'NON_NULL',
              name: null,
              ofType: {
                __typename: '__Type',
                kind: 'SCALAR',
                name: 'jsonb',
                ofType: null,
              },
            },
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        interfaces: [],
        enumValues: null,
        possibleTypes: null,
      },
    ],
  },
};

type Params = {
  tables: typeof sampleData.data.db_get_tables;
  constraints: typeof sampleData.data.db_get_constraints;
  schema: typeof sampleIntrospec.__schema;
};

export function getDataRelation(params: Params) {
  const { tables, constraints, schema } = params;

  /**
   * Get primary key in constraints
   */
  function getPk(table: string) {
    const item = constraints.find((item) => {
      return (
        item.data.tableName === table &&
        item.data.constraint_type === 'PRIMARY KEY'
      );
    });
    return item?.data.columnName;
  }

  function getForeignConstraints(table: string, foreignTable: string) {
    return constraints.find((item) => {
      return (
        item.data.tableName === table &&
        item.data.foreignTableName === foreignTable &&
        item.data.constraint_type === 'FOREIGN KEY'
      );
    });
  }

  /**
   * Helper to get relation of field
   */
  function getRelationTo(type: {
    name: string | null;
    ofType?: any;
  }): string | undefined {
    if (type?.name) {
      return type.name;
    }
    if (type?.ofType) {
      return getRelationTo(type.ofType);
    }
    return;
  }

  const result = tables.map<IntroTable>(({ key: table }) => {
    const selectFields = find(schema.types, { name: table })?.fields.filter(
      (item) => {
        // fitler out select aggregate, ex: select.ForRents_aggregate beside select.ForRents
        return item.name.indexOf('_aggregate') < 0;
      }
    );

    const createFields = find(schema.types, {
      name: `${table}_insert_input`,
    })?.inputFields;

    const updateFields = find(schema.types, {
      name: `${table}_set_input`,
    })?.inputFields;

    const pk = getPk(table) as string;

    const fields = (selectFields || []).reduce<Record<string, TableField>>(
      (prev, item) => {
        const { name, type, args } = item;

        // table name will upper first case as our rule
        const isRelationField = name[0] === name[0].toUpperCase();
        const relation: Partial<TableField> = {};
        if (isRelationField) {
          // there nested type, parse to
          relation.to = getRelationTo(type);

          if (!relation.to) {
            throw new Error(
              `Cannot parse relation.to of relation field ${table}.${name}`
            );
          }

          // if array relation, args will be [{name: where}, {name: limit}...]
          // otherwise args = []
          relation.type = args.length ? 'ARRAY' : 'OBJECT';
          const isObjectRelation = relation.type === 'OBJECT';

          // Get 2 way constraints
          const constraints = [
            // field -> foreign primary key || primary key <- foreign field
            getForeignConstraints(table, relation.to),
            // primary key <- foreign field
            getForeignConstraints(relation.to, table),
          ];

          // use constraint
          const constraint = !isObjectRelation
            ? constraints[1]
            : constraints[0] || constraints[1];

          if (!constraint) {
            console.warn(
              `Need a constraint to detect relation ${table} <-> ${relation.to}`
            );
            return prev;
          }

          const cols = [
            constraint?.data.columnName,
            constraint?.data.foreignColumnName,
          ] as string[];
          constraint.key !== constraints[0]?.key && cols.reverse(); // reverse if use ex constraint
          relation.key = cols[0];
          relation.foreignKey = cols[1];
        }

        prev[name] = {
          name,
          ...relation,
          permissions: {
            select: true, // always true because we handle selectable field only
            create: !!createFields?.find((item) => item.name === name),
            update: !!updateFields?.find((item) => item.name === name),
          },
        };
        return prev;
      },
      {}
    );

    const fieldArr = Object.values(fields || {});
    const mutationRoot = find(schema.types, { name: 'mutation_root' });
    return {
      name: table,
      pk,
      fields,
      permissions: {
        select: fieldArr.map((item) => item.permissions.select).some(Boolean),
        create: fieldArr.map((item) => item.permissions.create).some(Boolean),
        update: fieldArr.map((item) => item.permissions.update).some(Boolean),
        delete: !!(mutationRoot?.fields || []).find(
          (item: any) => item.name === `delete_${table}_by_pk`
        ),
      },
    };
  });

  const resultIndexed = keyBy(result, 'name');

  // the extra step to set relation field permission
  // because ARRAY relation have to use replate mutation (2 mutation for update)
  // then we cannot found updateFields[relationFieldKey]
  // this check permission update on relation table, the reset the field permissions
  forEach(resultIndexed, (item) => {
    forEach(item.fields, (field) => {
      if (field.to) {
        field.permissions.update =
          resultIndexed[field.to] && resultIndexed[field.to].permissions.update;
      }
    });
  });

  // @@@ this cause error, should handle this case soon
  delete resultIndexed.spatial_ref_sys;

  return resultIndexed;
}
