import { Select } from 'antd';
import moment from 'moment';
import React from 'react';
import { DateInput } from '../../../components/inputs/DateInput';
import getRenders from '../../../utils/getRenders';
import {
  RenderColumn,
  RenderField,
  RenderRenderOptions,
  RenderText,
} from '../types';

type RenderOptions = {
  format: 'date' | 'datetime';
};

const { formItems } = getRenders({
  formItems: {
    format: {
      name: ['options', 'format'],
      label: 'Format',
      children: React.createElement(Select, {
        placeholder: 'Select date format',
        options: [
          { value: 'date', label: 'Date' },
          { value: 'datetime', label: 'Date Time' },
        ],
      }),
    },
  },
});

export const renderRenderOptions: RenderRenderOptions = (field) => {
  return [formItems.format({ key: 'format' })];
};

export const renderField: RenderField<RenderOptions> = ({ col }) => {
  return React.createElement(DateInput, {
    format: col.options?.format,
  });
};

export const renderColumn: RenderColumn<RenderOptions> = (
  { value },
  { col }
) => {
  const format = col.options?.format;

  let f = 'YYYY-MM-DD';
  if (format === 'datetime') {
    f += ' HH:mm';
  }
  return moment(new Date(value)).format(f);
};

export const renderText = renderColumn as RenderText;
