import {
  InputType,
  RenderBulkEdit,
  RenderColumn,
  RenderField,
  RenderRenderOptions,
  RenderRenderOptionsViewMode,
  RenderText,
} from './types';
import * as addressConfig from './input-types/address';
import * as areaConfig from './input-types/area';
import * as dateConfig from './input-types/date';
import * as jsonConfig from './input-types/json';
import * as numberConfig from './input-types/number';
import * as objectConfig from './input-types/object';
import * as photoConfig from './input-types/photo';
import * as radioConfig from './input-types/radio';
import * as relatedArrayConfig from './input-types/relatedArray';
import * as richTextConfig from './input-types/richText';
import * as selectConfig from './input-types/select';
import * as textConfig from './input-types/text';
import { renderRenderOptionsViewModeJSON } from './input-types/shared';

type InputConfig = {
  renderRenderOptions?: RenderRenderOptions;
  renderRenderOptionsViewMode?: RenderRenderOptionsViewMode;
  renderField?: RenderField;
  renderBulkEdit?: RenderBulkEdit;
  renderColumn?: RenderColumn;
  renderText?: RenderText;
};

/**
 * All suported inputs config
 */
const InputTypes: {
  [T in InputType]: InputConfig;
} = {
  [InputType.TEXT]: textConfig,
  [InputType.NUMBER]: numberConfig,
  [InputType.RADIO]: radioConfig,
  [InputType.DATE]: dateConfig,
  [InputType.SELECT]: selectConfig,
  [InputType.ADDRESS]: addressConfig,
  [InputType.AREA]: areaConfig,
  [InputType.PHOTO]: photoConfig,
  [InputType.JSON]: jsonConfig,
  [InputType.RELATED_ARRAY]: relatedArrayConfig,
  [InputType.OBJECT]: objectConfig,
  [InputType.RICH_TEXT]: richTextConfig,
};

export default InputTypes;

/**
 * Helper to get input config to always return renders of input type = any
 */
export function getInputConfig(type: InputType): InputConfig {
  return {
    renderRenderOptions: () => null, // no options by default
    renderRenderOptionsViewMode: renderRenderOptionsViewModeJSON,
    renderField: ({ col }) => `${col.type}::renderField`, // should extends
    renderBulkEdit: (_, { col }) => `${col.type}::renderBulkEdit`, // should extends
    renderColumn: (_, { col }) => `${col.type}::renderColumn`, // should extends
    renderText: (_, { col }) => `${col.type}::renderText`, // should extends
    ...InputTypes[type],
  };
}
