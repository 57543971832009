import {
  Card,
  Col,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Space,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import { PageProps } from 'gatsby';
import React, { useEffect, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { ArrayInput } from '../../components/inputs/ArrayInput';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import Layout, { LayoutProps } from '../../components/Layout';
import { QuickForm, QuickFormProps } from '../../components/QuickForm';
import useJsonInput from '../../hooks/useJsonInput';
import useCurrentUser from '../../modules/app/useCurrentUser';
import {
  useTableCreate,
  useTableUpdate,
  useTableSearch,
} from '../../modules/row-data/queryHooks';
import { InputBaseProps } from '../../utils/common';
import createPrivatePage from '../../utils/createPrivatePage';
import getRenders from '../../utils/getRenders';

function ExtraServiceInput(props: InputBaseProps<any>) {
  const { getInputProps } = useJsonInput(props);
  return (
    <Space direction="vertical">
      <Row gutter={16}>
        <Col>
          <Input
            {...getInputProps('code')}
            placeholder="Code"
            style={{ width: 100 }}
          />
        </Col>
        <Col>
          <Input
            {...getInputProps('name')}
            placeholder="Name"
            style={{ width: 200 }}
          />
        </Col>
        <Col>
          <InputNumber
            {...getInputProps('value')}
            placeholder="Price"
            addonAfter={
              <Select
                style={{ width: 100 }}
                {...getInputProps('valueType')}
                defaultValue="amount"
              >
                <Select.Option value="percent">%</Select.Option>
                <Select.Option value="amount">USD</Select.Option>
              </Select>
            }
          />
        </Col>
        <Col>
          <CheckboxInput {...getInputProps('enable')}>Enable</CheckboxInput>
        </Col>
      </Row>
      <TextArea {...getInputProps('desc')} placeholder="Description" />
    </Space>
  );
}

const { formItems } = getRenders({
  formItems: {
    notifyEmails: {
      name: 'notifyEmails',
      label: <strong>Email notifications (comma separate):</strong>,
      children: <Input placeholder="Email notifications" />,
    },
    extraServices: {
      name: 'extraServices',
      label: <strong>Extra services on ride:</strong>,
      children: (
        <ArrayInput
          defaultRowValue={{ valueType: 'amount', enable: true }}
          Input={ExtraServiceInput}
        />
      ),
    },
  },
});

function PartnerSettings(props: PageProps) {
  const { data: currentUser } = useCurrentUser();
  const load = useTableSearch('UserSetting', {
    variables: {
      where: {
        userId: {
          _eq: currentUser?.id,
        },
      },
    },
    skip: !currentUser,
  });
  const [create, createResult] = useTableCreate('UserSetting');
  const [update, updateResult] = useTableUpdate('UserSetting');
  const [form] = useForm();

  const data =
    updateResult.data?.update_UserSetting_by_pk ||
    load.data?.records?.[0] ||
    createResult.data;
  const error = updateResult.error || createResult.error;
  const hasSubmit = useRef(false);

  useEffect(() => {
    if (hasSubmit.current && data) {
      message.success('Data saved');
      hasSubmit.current = false;
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      message.error(error.message || 'Unknown error');
    }
  }, [error]);

  const breadcrumb = useMemo<LayoutProps['breadcrumb']>(() => {
    return [
      { title: 'Home', path: '/' },
      { title: 'Partner Settings', path: '/partner-settings' },
    ];
  }, []);

  const onFinish: QuickFormProps['onFinish'] = (values) => {
    hasSubmit.current = true;
    if (data?.id) {
      update({
        variables: {
          pk: data.id,
          data: values,
        },
      });
    } else {
      create({
        variables: {
          data: values,
        },
      });
    }
  };

  return (
    <Layout breadcrumb={breadcrumb}>
      <Helmet>
        <title>Partner Settings</title>
      </Helmet>
      <Card title="Partner Settings" loading={load.loading}>
        {!load.loading && load.data.records && (
          <QuickForm
            form={form}
            layout="vertical"
            name="basic"
            initialValues={data}
            onFinish={onFinish}
            actionButtons={[
              {
                children: 'Submit',
                htmlType: 'submit',
                type: 'primary',
                loading: updateResult.loading,
                disabled: updateResult.loading,
              },
            ]}
          >
            {formItems.notifyEmails()}
            {formItems.extraServices()}
          </QuickForm>
        )}
      </Card>
    </Layout>
  );
}

export default createPrivatePage(PartnerSettings);
