import { cloneDeep, get, set } from 'lodash';
import { useCallback } from 'react';
import { InputBaseProps } from '../utils/common';

type PropPath = string | string[];

/**
 * Input helper for json edit
 */
export default function useJsonInput<T = any>(props: InputBaseProps<T>) {
  const { value, onChange } = props;

  const handleChange = useCallback(
    (p: PropPath) => {
      return (e: React.ChangeEvent<HTMLInputElement> | any) => {
        const v = e?.target ? e.target.value : e;
        const nextValue = set(cloneDeep(value || {}), p, v);
        onChange && onChange(nextValue as T);
      };
    },
    [value, onChange]
  );

  const getValue = useCallback(
    (p: PropPath) => {
      return get(value, p);
    },
    [value, onChange]
  );

  const getInputProps = useCallback(
    (p: PropPath) => {
      return {
        value: getValue(p),
        onChange: handleChange(p),
      };
    },
    [handleChange, getValue]
  );

  return { getValue, handleChange, getInputProps };
}
