import React from 'react';
import RichTextInput from '../../../components/inputs/RichTextInput';
import { InputType, RenderColumn, RenderField } from '../types';

export const renderField: RenderField = ({ col }) => {
  return React.createElement(RichTextInput, {});
};

export const renderColumn: RenderColumn = ({ value }) => {
  return null;
};
