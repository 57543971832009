import React, { useMemo } from 'react';
import { PageProps } from 'gatsby';
import createPrivatePage from '../../utils/createPrivatePage';
import Layout, { LayoutProps } from '../../components/Layout';
import { Helmet } from 'react-helmet';
import {
  Alert,
  Card,
  Col,
  Row,
  Space,
  Table,
  TableProps,
  Tag,
  Typography,
} from 'antd';
import { gql, useQuery } from '@apollo/client';
import moment from 'moment';
import { formatPrice } from './BookingTracking';
import VSpace from '../../components/VSpace';
import { getMediaUrl, MediaFile } from '../../utils/media';
import { FileTextOutlined, MinusOutlined } from '@ant-design/icons';

const GET_PAYOUT = gql`
  query MyQuery($id: uuid = "") {
    Payout_by_pk(id: $id) {
      amount
      id
      createdOn
      attachments
      status
      Bookings(order_by: { updatedOn: desc }, limit: 99999) {
        code
        id
        total
        status
        paymentType
      }
    }
  }
`;

function PayoutDetail(props: PageProps) {
  const params = new URLSearchParams(props.location.search);
  const id = params.get('id');
  const { data } = useQuery(GET_PAYOUT, {
    variables: {
      id,
    },
    skip: !id,
    fetchPolicy: 'cache-and-network',
  });

  const record = data?.Payout_by_pk;

  const breadcrumb = useMemo<LayoutProps['breadcrumb']>(() => {
    return [
      { title: 'Home', path: '/' },
      { title: 'Payout', path: '/Payout/list' },
      { title: 'Detail', path: '#' },
    ];
  }, []);

  const bookings = useMemo(() => {
    return (record?.Bookings || []).map((item: any) => {
      const total = item.total?.total || 0;
      const appFee = item.total?.appFee || 0;
      return {
        ...item,
        payout: item.paymentType === 'CARD' ? total - appFee : -appFee,
      };
    });
  }, [record]);

  const columns = useMemo<TableProps<any>['columns']>(() => {
    return [
      {
        title: 'Booking Code',
        dataIndex: 'code',
        render: (value) => {
          return `JR-${value}`;
        },
      },
      { title: 'Status', dataIndex: 'status' },
      { title: 'Payment Type', dataIndex: 'paymentType' },
      {
        payout: 'Payout',
        dataIndex: 'payout',
        render: (value) => {
          return (
            <div style={{ textAlign: 'right' }}>
              <Typography.Text>
                {formatPrice(value, { showPositive: true })}
              </Typography.Text>
            </div>
          );
        },
      },
    ];
  }, []);

  return (
    <Layout breadcrumb={breadcrumb}>
      <Helmet>
        <title>Payout Details</title>
      </Helmet>
      {!!record && (
        <Card>
          <VSpace size={30}>
            <Row gutter={16}>
              <Col>
                <Typography.Text>
                  <strong>Created:</strong>{' '}
                  {moment(new Date(record.createdOn)).format(
                    'YYYY-MM-DD HH:mm'
                  )}
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text>
                  <strong>Amount:</strong> {formatPrice(record.amount)}
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text>
                  <strong>Status:</strong>{' '}
                  <Tag
                    color={
                      record.status === 'COMPLETED' ? '#108ee9' : undefined
                    }
                  >
                    {record.status}
                  </Tag>
                </Typography.Text>
              </Col>
            </Row>
            <Alert type="info" message="Weekly payout and at least $100" />
            <Table
              rowKey="id"
              bordered
              pagination={false}
              dataSource={bookings}
              columns={columns}
            />
            <VSpace>
              {(record.attachments || []).map((item: MediaFile) => {
                const url = getMediaUrl(item, 'original');
                return (
                  <a key={item.filename} href={url as string} target="_blank">
                    <FileTextOutlined /> {item.title || item.filename}
                  </a>
                );
              })}
            </VSpace>
          </VSpace>
        </Card>
      )}
    </Layout>
  );
}

export default createPrivatePage(PayoutDetail);
