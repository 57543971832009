import { InputNumber } from 'antd';
import React from 'react';
import { InputType, RenderColumn, RenderField, RenderText } from '../types';

export const renderField: RenderField = ({ col }) => {
  return React.createElement(InputNumber, {
    placeholder: col.title,
  });
};

export const renderColumn: RenderColumn = ({ value }) => {
  return value;
};

export const renderText = renderColumn as RenderText;
