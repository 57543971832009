import TextArea, { TextAreaProps } from 'antd/lib/input/TextArea';
import React, { useState } from 'react';
import { InputBaseProps } from '../../../utils/common';

export type JsonInputProps = InputBaseProps<any>;

/**
 * @@@ DEV
 * - should add json validate schema for safe data
 */
export default function JsonInput(props: JsonInputProps) {
  const { value, onChange, readOnly } = props;
  const [status, setStatus] = useState<TextAreaProps['status']>();

  const onBlur: TextAreaProps['onBlur'] = (e) => {
    try {
      const json = JSON.parse(e.target.value);
      setStatus('');
      onChange && onChange(json);
    } catch (err) {
      setStatus('error');
    }
  };

  return (
    <TextArea
      status={status}
      rows={6}
      spellCheck={false}
      defaultValue={stringify(value)}
      onBlur={onBlur}
      readOnly={readOnly}
    />
  );
}

function stringify(json: any) {
  return JSON.stringify(json || {}, null, 2);
}
