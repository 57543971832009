import React from 'react';
import RadioInput from '../../../components/inputs/RadioInput';
import { InputType, RenderColumn, RenderField } from '../types';

export const renderField: RenderField = ({ col }) => {
  return React.createElement(RadioInput, {
    options: col.options.options,
  });
};

export const renderColumn: RenderColumn = ({ value }) => {
  return value + '';
};

// <JsonInput
// value={value.options.options}
// onChange={(value) => {
//   change('options.options', value);
// }}
// />
