// import brandhub, { BeType } from '../../sites/brandhub';
import jottrip, { BeType } from '../../sites/jottrip';
import SiteBase from '../../sites/SiteBase';

/**
 * Ready config exported
 */
export const config: SiteBase = jottrip;
export type ExtraBeType = BeType;
export default config;
