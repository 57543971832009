import React from 'react';
import CellPhoto from '../../../cells/CellPhoto';
import PhotoInput from '../../../components/inputs/PhotoInput';
import { InputType, RenderColumn, RenderField } from '../types';

export const renderField: RenderField = ({ col }) => {
  return React.createElement(PhotoInput);
};

export const renderColumn: RenderColumn = ({ value }) => {
  return React.createElement(CellPhoto, {
    value,
  });
};
