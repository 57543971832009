export type MapAPI = any;
export type MapInstance = any;

/**
 * Create a control button style and attach event
 * https://developers.google.com/maps/documentation/javascript/examples/control-custom
 * @returns Element
 */
export const getControlButtonElement = (options?: {
  title?: string;
  label?: string;
  onClick?: () => void;
}) => {
  const { title = '', label = 'Button', onClick = () => null } = options || {};
  const controlUI = document.createElement('div');
  controlUI.style.backgroundColor = '#fff';
  controlUI.style.border = '2px solid #fff';
  controlUI.style.borderRadius = '3px';
  controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
  controlUI.style.cursor = 'pointer';
  controlUI.style.margin = '8px';
  controlUI.style.textAlign = 'center';
  controlUI.title = title || label;

  // Set CSS for the control interior.
  const controlText = document.createElement('div');
  controlText.style.color = 'rgb(25,25,25)';
  controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
  controlText.style.fontSize = '16px';
  controlText.style.lineHeight = '38px';
  controlText.style.paddingLeft = '5px';
  controlText.style.paddingRight = '5px';
  controlText.innerHTML = label;
  controlUI.appendChild(controlText);

  controlUI.addEventListener('click', () => {
    onClick();
  });

  return controlUI;
};

/**
 * Create google map polygon from coordinates
 * @returns polygon shape
 */
export const getCoordinatesPolygon = (
  mapAPI: MapAPI,
  coordinates: number[][],
  drawOption?: any
) => {
  const coords = coordinates.map((arr: number[]) => {
    return {
      lat: arr[1],
      lng: arr[0],
    };
  });

  // Construct the polygon.
  const s = new mapAPI.Polygon({
    paths: coords,
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    ...drawOption,
  });

  // Auto add prototype getBounds for polygon because map v3 not have by default
  if (!mapAPI.Polygon.prototype.getBounds) {
    mapAPI.Polygon.prototype.getBounds = function () {
      const bounds = new mapAPI.LatLngBounds();
      const paths = this.getPaths();
      let path;
      for (let i = 0; i < paths.getLength(); i++) {
        path = paths.getAt(i);
        for (let ii = 0; ii < path.getLength(); ii++) {
          bounds.extend(path.getAt(ii));
        }
      }
      return bounds;
    };
  }

  return s;
};

/**
 * Get coordinates of polygon
 * https://developers.google.com/maps/documentation/javascript/reference/drawing#DrawingManager.polygoncomplete
 */
export const getPolygonCoordinates = (polygonPaths: any) => {
  const coordinates = [];
  for (let j = 0; j < polygonPaths.length; j++) {
    coordinates.push([
      polygonPaths.getAt(j).lng(),
      polygonPaths.getAt(j).lat(),
    ]);
  }
  coordinates.push(coordinates[0]); // must linearring
  return coordinates;
};

/**
 * Quick get drawing controls
 */
export const getDrawing = (
  mapAPI: MapAPI,
  options?: {
    modes?: string[];
    position?: string;
    markerIcon?: string;
  }
) => {
  const {
    // MARKER CIRCLE POLYLINE RECTANGLE
    modes = [mapAPI.drawing.OverlayType.POLYGON],
    position = mapAPI.ControlPosition.TOP_CENTER,
    markerIcon = 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png',
  } = options || {};

  const drawingManager = new mapAPI.drawing.DrawingManager({
    drawingMode: mapAPI.drawing.OverlayType.POLYGON,
    drawingControl: true,
    drawingControlOptions: {
      position,
      drawingModes: modes,
    },
    markerOptions: {
      icon: markerIcon,
    },
    circleOptions: {
      fillColor: '#ffff00',
      fillOpacity: 1,
      strokeWeight: 5,
      clickable: false,
      editable: true,
      zIndex: 1,
    },
  });
  return drawingManager;
};
