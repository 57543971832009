import { isFunction } from 'lodash';
import useReactiveVar, { makeVar, ReactiveVar } from '../hooks/useReactiveVar';

/**
 * Optional input is reactive var or initial for reactive var
 */
export default function generateVarHook<V>(input: V | ReactiveVar<V>) {
  const reVar = isFunction(input)
    ? (input as ReactiveVar<V>)
    : makeVar<V>(input as V);

  function setVar<K extends keyof V>(key: K, value: V[K]): V;
  function setVar(merge: Partial<V>): V;
  function setVar() {
    const [key, value] = arguments;
    let merge: any = {};
    if (typeof key === 'string') {
      merge = { [key]: value };
    } else {
      merge = key;
    }
    return reVar((prev) => ({ ...prev, ...merge }));
  }

  return function useVarHook() {
    const currentVar = useReactiveVar(reVar);
    return [currentVar, setVar] as [V, typeof setVar];
  };
}
