import { PageProps } from 'gatsby';
import React, { ComponentClass, FunctionComponent } from 'react';
import useTokens from '../modules/app/useTokens';
import Login from '../pages/login';

/**
 * Protect page with Login by a wrapper
 */
export default function createPrivatePage(
  component: FunctionComponent<PageProps> | ComponentClass<PageProps>
) {
  return (props: PageProps) => {
    const { isUserLogged } = useTokens();
    if (!isUserLogged) {
      return React.createElement(Login, props);
    }
    return React.createElement(component, props);
  };
}
