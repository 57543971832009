import React, { useEffect, useRef, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { Input, InputRef } from 'antd';
import { InputBaseProps } from '../../../utils/common';
import config from '../../../modules/dynamic-config';

type MarkerProps = {
  text?: string;
  onClick?: () => void;
  [key: string]: any;
};
const Marker: React.FC<MarkerProps> = ({ text, onClick }) => (
  <div
    style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '8px',
      height: '8px',
      backgroundColor: '#000',
      border: '2px solid #fff',
      borderRadius: '100%',
      userSelect: 'none',
      transform: 'translate(-50%, -50%)',
      cursor: onClick ? 'pointer' : 'default',
    }}
    title={text}
    onClick={onClick}
  />
);

export type SearchBoxProps = {
  mapApi: any;
  mapInstance: any;
  initialValue?: string;
  onPlace?: (place: any) => void;
  style?: React.CSSProperties;
};

const SearchBox: React.FC<SearchBoxProps> = ({
  mapApi,
  mapInstance,
  initialValue,
  style,
  onPlace,
}) => {
  const searchInputRef = useRef<InputRef>();

  useEffect(() => {
    const searchInput = searchInputRef.current?.input;
    if (!searchInput) {
      return;
    }

    // Set initial value
    if (initialValue) {
      searchInput.value = initialValue;
    }

    const onPlacesChanged = () => {
      const selected = searchBox.getPlaces();
      const { 0: place } = selected;
      if (!place.geometry) return;
      if (place.geometry.viewport) {
        mapInstance.fitBounds(place.geometry.viewport);
      } else {
        mapInstance.setCenter(place.geometry.location);
        mapInstance.setZoom(17);
      }
      onPlace && selected?.[0] && onPlace(selected[0]);
      searchInput.blur();
    };

    const searchBox = new mapApi.places.SearchBox(searchInput);
    searchBox.addListener('places_changed', onPlacesChanged);
    searchBox.bindTo('bounds', mapInstance);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      mapApi.event.clearInstanceListeners(searchInput);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Input
      ref={searchInputRef as any}
      placeholder="Enter a location"
      style={style}
    />
  );
};

export type Address = {
  title: string;
  marker: number[]; // [lat, lng]
  [key: string]: any; // { place: google place details }
};

export type MapInputProps = InputBaseProps<Address>;

const MapInput: React.FC<MapInputProps> = ({ value, onChange }) => {
  const [loadedMap, setLoadedMap] = useState<any>();

  return (
    <>
      {loadedMap && (
        <SearchBox
          mapInstance={loadedMap.mapInstance}
          mapApi={loadedMap.mapApi}
          initialValue={value?.title}
          onPlace={(place) => {
            onChange &&
              onChange({
                id: place.id,
                title: place.name,
                marker: [
                  place.geometry.location.lat(),
                  place.geometry.location.lng(),
                ],
              });
          }}
          style={{
            marginBottom: 5,
          }}
        />
      )}
      <div style={{ height: 200 }}>
        <GoogleMapReact
          center={
            value && value.marker[0] && value.marker[1] // dev: should validate marker to prevent throw error
              ? { lat: value.marker[0], lng: value.marker[1] }
              : { lat: 10.7735994, lng: 106.6944173 }
          }
          zoom={9}
          bootstrapURLKeys={{
            key: config.google.mapApi,
            libraries: ['places', 'geometry'],
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map: mapInstance, maps: mapApi }: any) => {
            mapInstance &&
              mapApi &&
              setLoadedMap({
                mapApi,
                mapInstance,
              });
          }}
        >
          {value && (
            <Marker
              key={value.id || value.title}
              text={value.title}
              lat={value.marker[0]}
              lng={value.marker[1]}
            />
          )}
        </GoogleMapReact>
      </div>
    </>
  );
};

export default MapInput;
