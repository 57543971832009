import { gql, useMutation } from '@apollo/client';

const MARK_CONVERSATION_READ = gql`
  mutation MyMutation($conversationId: uuid!) {
    delete_ConversationUnread(
      where: { conversationId: { _eq: $conversationId } }
    ) {
      returning {
        messageId
      }
    }
  }
`;
export default function useMarkReadConversation(conversationId: string) {
  const [markRead] = useMutation(MARK_CONVERSATION_READ, {
    variables: {
      conversationId,
    },
    fetchPolicy: 'no-cache',
  });

  return markRead;
}
