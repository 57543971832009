import React from 'react';
import { Radio } from 'antd';
import { InputBaseProps } from '../../../utils/common';

type RadioOption = {
  value: string | boolean | number;
  label?: string;
  color?: string;
  key?: string;
};

export type RadioInputProps = InputBaseProps<string> & {
  options: RadioOption[];
};

export default function RadioInput(props: RadioInputProps) {
  const { value, onChange, options } = props;

  return (
    <Radio.Group
      value={value}
      onChange={(e) => {
        onChange && onChange(e.target.value);
      }}
    >
      {options.map(({ value, label, key }, index) => (
        <Radio key={key || index.toString()} value={value}>
          {label || value}
        </Radio>
      ))}
    </Radio.Group>
  );
}
