import {
  Menu,
  Breadcrumb,
  Layout as AntdLayout,
  BreadcrumbProps,
  Row,
  Col,
  Typography,
  Space,
  Avatar,
  Dropdown,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import {
  AimOutlined,
  AntDesignOutlined,
  FormOutlined,
  LogoutOutlined,
  MailOutlined,
  UserOutlined,
} from '@ant-design/icons';
import SubMenu from 'antd/lib/menu/SubMenu';
import React, { useCallback, useMemo } from 'react';
import { Link, navigate } from 'gatsby';
import { MenuItem } from '../../utils/common';
import { useAppContext } from '../../modules/app';
import useTokens from '../../modules/app/useTokens';
import useCurrentUser from '../../modules/app/useCurrentUser';
import config from '../../modules/dynamic-config';
import { upperFirst } from 'lodash';
const { Header, Content, Sider } = AntdLayout;

const DotSvg = (props: any) => {
  return (
    <svg
      {...props}
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 30 30"
    >
      <circle cx="15" cy="15" r="5" fill="black" />
    </svg>
  );
};

function renderMenus(menus: MenuItem[]) {
  return menus.map(({ path, title, icon = <DotSvg />, subMenus }) => {
    if (subMenus?.length) {
      return (
        <SubMenu key={path} icon={icon} title={title}>
          {renderMenus(subMenus)}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={path} icon={icon}>
        <Link to={path}>{title}</Link>
      </Menu.Item>
    );
  });
}

const renderBreadcrumbItem: BreadcrumbProps['itemRender'] = (
  route,
  params,
  routes,
  paths
) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={route.path}>{route.breadcrumbName}</Link>
  );
};

export type LayoutProps = {
  breadcrumb?: MenuItem[];
  subMenus?: MenuItem[];
  pageTitle?: React.ReactNode;
  pageSubTitle?: React.ReactNode;
};

const sliderWidth = 250;

/**
 * Admin dashboard layout
 */
export const Layout: React.FC<LayoutProps> = ({
  pageTitle,
  pageSubTitle,
  breadcrumb = [],
  subMenus = [],
  children,
}) => {
  const { removeTokens } = useTokens();
  const { data: currentUser } = useCurrentUser();
  const { menus } = useAppContext();

  const routeMatch = { url: '/' };
  const loggedAs = currentUser?.name || currentUser?.email;
  const roleName =
    currentUser?.role && upperFirst(currentUser?.role.toLowerCase());
  const hasSubMenu = !!subMenus.length;
  const contentMarignLeft = sliderWidth + (hasSubMenu ? 80 : 0);

  const breadcrumbRoutes = useMemo<BreadcrumbProps['routes']>(() => {
    return breadcrumb.map((item) => {
      return {
        path: item.path,
        breadcrumbName: item.title,
      };
    });
  }, [breadcrumb]);

  const logout = useCallback(() => {
    removeTokens();
    navigate('/');
  }, []);

  return (
    <AntdLayout style={{ minHeight: '100vh' }}>
      <Header
        style={{
          position: 'fixed',
          width: '100%',
          padding: 0,
          boxShadow: 'rgb(40 42 49 / 16%) 0px 1px 2px 0px',
          zIndex: 999, // for boxShadow to show
          backgroundColor: 'white',
        }}
      >
        <Row justify="space-between">
          <Col
            style={{
              width: sliderWidth,
              // backgroundColor: '#007EFF',
              height: 64,
              lineHeight: 1,
            }}
          >
            <Link to="/">
              <Space size={12} style={{ height: 64, marginLeft: 12 }}>
                <div
                  style={{
                    backgroundColor: '#8c4bff',
                    width: 32,
                    height: 32,
                  }}
                />
                <Space direction="vertical" size={3}>
                  <Text strong>{config.siteTitle} Admin</Text>
                  <Text>
                    <small>{roleName}</small>
                  </Text>
                </Space>
              </Space>
            </Link>
          </Col>
          <Col style={{ overflow: 'hidden', paddingRight: 16 }}>
            <Row gutter={8}>
              <Col>
                <Dropdown
                  overlay={
                    <Menu
                      theme="light"
                      mode="vertical"
                      style={{
                        width: 200,
                      }}
                    >
                      <Menu.Item key="loggedAs" style={{ lineHeight: 1 }}>
                        <Space>
                          <Avatar
                            size="large"
                            shape="square"
                            icon={<UserOutlined />}
                          />
                          <Space direction="vertical" size={3}>
                            <Text strong>{loggedAs}</Text>
                            <Text>
                              <small>{roleName}</small>
                            </Text>
                          </Space>
                        </Space>
                      </Menu.Item>
                      <Menu.Item key="myAccount" icon={<UserOutlined />}>
                        <Link to="/profile">My Account</Link>
                      </Menu.Item>
                      <Menu.Item
                        key="logout"
                        icon={<LogoutOutlined />}
                        onClick={logout}
                      >
                        Logout
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Avatar shape="square" icon={<UserOutlined />} />
                </Dropdown>
              </Col>
              <Col>{config.getExtraHeaderRight()}</Col>
            </Row>
          </Col>
        </Row>
      </Header>
      <AntdLayout>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            overflow: 'auto',
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0,
            marginTop: 64,
            overflowX: 'hidden',
            overflowY: 'auto',
            width: contentMarignLeft,
          }}
        >
          <Sider width={sliderWidth} collapsed={hasSubMenu} theme="light">
            <Menu mode="inline" selectedKeys={[routeMatch.url]} theme="light">
              {renderMenus(menus)}
            </Menu>
          </Sider>
          {hasSubMenu && (
            <Sider width={sliderWidth} theme="light">
              <Menu mode="inline" selectedKeys={[routeMatch.url]}>
                {renderMenus(subMenus)}
              </Menu>
            </Sider>
          )}
        </div>
        <AntdLayout
          style={{
            backgroundColor: '#FAFAFB',
            marginLeft: contentMarignLeft,
            marginTop: 64,
          }}
        >
          {/* <PageHeader
            onBack={() => null}
            breadcrumb={{
              routes: breadcrumbRoutes,
              itemRender: renderBreadcrumbItem,
            }}
            title={pageTitle}
            subTitle={pageSubTitle}
            backIcon={false}
          /> */}
          <Content style={{ padding: 24 }}>
            <Breadcrumb
              routes={breadcrumbRoutes}
              itemRender={renderBreadcrumbItem}
              style={{ marginBottom: 16 }}
            />
            {children}
          </Content>
        </AntdLayout>
      </AntdLayout>
    </AntdLayout>
  );
};

export default Layout;
