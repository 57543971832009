import { startCase } from 'lodash';
import React from 'react';
import ObjectInput from '../../../components/inputs/ObjectInput';
import { InputType, RenderColumn, RenderField } from '../types';

export const renderField: RenderField = ({ col }) => {
  return React.createElement(ObjectInput, {
    rows: col.options.rows,
  });
};

export const renderColumn: RenderColumn = (cell, { col }) => {
  return Object.keys(cell.value || {}).map((key) =>
    React.createElement('div', {
      key,
      children: `${startCase(key)}: ${cell.value[key]}`,
    })
  );
};

// <Space direction="vertical">
// <Text code>
//   {`
//   {
//     key: string;
//     type: 'text' | 'number' | 'date';
//     default: any;
//     inputProps: any;
//   }[]
// `}
// </Text>
// <Field label="Object props">
//   <JsonInput
//     value={value.options.rows}
//     onChange={(value) => {
//       change('options.rows', value);
//     }}
//   />
// </Field>
// </Space>
