import React, { PropsWithChildren, useEffect } from 'react';
import Icon, {
  DeploymentUnitOutlined,
  InsertRowAboveOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import { MenuItem } from '../../utils/common';
import { useKeyData } from '../hasura/useKeyData';
import { useParsedIntrospection } from '../hasura/useParsedIntrospection';
import getResources from '../row-data/getResources';
import useCurrentUser from './useCurrentUser';
import useAppVar from './useAppVar';
import { isEmpty } from 'lodash';
import { PageLoading } from '../../components/Spinner';
import useTokens from './useTokens';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import config from '../dynamic-config';

export const useAppContext = () => {
  return useAppVar()[0];
};

const DotSvg = (props: any) => {
  return (
    <svg
      {...props}
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="0 0 30 30"
    >
      <circle cx="15" cy="15" r="5" fill="black" />
    </svg>
  );
};

const DotIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={DotSvg} {...props} />
);

/**
 * Wrap to init appVar
 */
function App({ children }: PropsWithChildren<{}>): JSX.Element {
  const [appVar, setAppVar] = useAppVar();
  const { data: dataTokens, isUserLogged } = useTokens();
  const {
    loading: userLoading,
    called: userCalled,
    data: user,
  } = useCurrentUser();
  const specResult = useParsedIntrospection();
  const tables = useKeyData(user ? 'dataSettings.tables' : undefined);
  const menuSettings = useKeyData(user ? 'dataSettings.menus' : undefined);

  // if has tokens (isUserLogged), it must userCalled
  // must called because loading initial state = false, then true, then loaded user
  const isLoadedAndNotLoggedIn =
    (!isUserLogged || userCalled) && !userLoading && !user;

  useEffect(() => {
    if (isLoadedAndNotLoggedIn) {
      setAppVar({
        menus: [],
        specs: {},
        tables: {},
        resources: {},
      });
      return;
    }

    // still loading
    if (!user || !specResult.data || !tables.data || !menuSettings.data) {
      return;
    }
    const resources = getResources({
      specs: specResult.data,
      dataSettings: tables.data,
    });

    // all menus by permissions
    const allMenus = Object.values(specResult.data || {}).reduce<MenuItem[]>(
      (prev, current) => {
        // hide KeyData, no need to raw edit
        if (current.permissions.select && current.name !== 'KeyData') {
          prev.push({
            path: `/${current.name}/list`,
            title: current.name,
            icon: <DotSvg />,
          });
        }
        return prev;
      },
      []
    );

    const roleMenus = menuSettings.data?.[user.role];
    let menus = isEmpty(roleMenus)
      ? allMenus
      : roleMenus.map((item: Partial<MenuItem>) => {
          const [pathType, tbl] = (item.path || '').split('::');
          return {
            icon: <DotSvg />,
            ...item,
            path: `/${tbl}/${pathType === '$list' ? 'list' : ''}`,
          };
        });

    menus = config.getMainMenus(menus, { user });

    setAppVar({
      menus,
      specs: specResult.data,
      tables: tables.data,
      resources,
    });
  }, [
    specResult.data,
    tables.data,
    menuSettings.data,
    user,
    isLoadedAndNotLoggedIn,
  ]);

  // Check refetch data on state tokens changed
  useEffect(() => {
    // If tokens state change = switch user
    if (!dataTokens.isLocal && dataTokens.tokens) {
      console.log('Refetch specs');
      specResult.refetch();

      if (tables.data) {
        // refetch if already has data only
        console.log('Refetch tables settings');
        tables.refetch();
      }
    }
  }, [dataTokens]);

  if (isEmpty(appVar)) {
    return <PageLoading />;
  }

  return children as JSX.Element;
}

export default App;
