import { useQuery, gql } from '@apollo/client';
import { camelCase, find, forEach, keyBy, uniq } from 'lodash';
import { useMemo } from 'react';
import { IntroTable, TableField } from './types';
import { getDataRelation } from './utils';

// /**
//  * First parse from raw data
//  * get tables that have permissions to access
//  */
// function getIndexedSchema(schemaTypes: any): { tables: string[] } {
//   const queryRoot = find(schemaTypes, { name: 'query_root' });
//   // const mutationRoot = find(schemaTypes, { name: 'mutation_root' });
//   // const subscriptionRoot = find(schemaTypes, { name: 'subscription_root' });

//   /**
//    * Try to get tables name on queryRoot
//    * because mutationRoot.fields can have something unsafe to parse
//    * ex: update_Booking, signIn, insert_User...
//    * Rarely table can update but cannot select
//    * If this case happen, we will do update this
//    */
//   const tables: string[] = uniq(
//     (queryRoot?.fields || []).map((field: any) => {
//       return field.name.split('_')[0];
//     })
//   );

//   return { tables };
// }

// /**
//  * Parse to intro table
//  * @params name table name
//  */
// function getIntroTable(name: string, schemaTypes: any): IntroTable | undefined {
//   const selectFields = keyBy(find(schemaTypes, { name })?.fields, 'name');
//   const createFields = keyBy(
//     find(schemaTypes, {
//       name: `${name}_insert_input`,
//     })?.inputFields,
//     'name'
//   );
//   const updateFields = keyBy(
//     find(schemaTypes, {
//       name: `${name}_set_input`,
//     })?.inputFields,
//     'name'
//   );
//   const pkFields = keyBy(
//     find(schemaTypes, {
//       name: `${name}_pk_columns_input`,
//     })?.inputFields,
//     'name'
//   );

//   let pk = Object.keys(pkFields)[0];
//   if (!pk) {
//     // if no permission for insert, we can not get _pk_columns_input
//     // then try to parse pk field
//     if (selectFields.id) {
//       // normal pk = id table
//       pk = 'id';
//     } else if (name === 'KeyData') {
//       // KeyData table
//       pk = 'key';
//     } else if (find(schemaTypes, { name: `${name}_enum` })) {
//       // enum table
//       pk = 'value';
//     } else {
//       console.warn(`Cannot parse pk key table ${name}`);
//       return;
//     }
//   }

//   // all table fields
//   const allFields = uniq(
//     [
//       Object.keys(selectFields),
//       Object.keys(createFields),
//       Object.keys(selectFields),
//     ].flat()
//   ).filter((item) => {
//     // fitler out select aggregate, ex: select.ForRents_aggregate beside select.ForRents
//     return item.indexOf('_aggregate') < 0;
//   });

//   // helper to get relation of field
//   function getRelationTo(type: {
//     name: string;
//     ofType?: any;
//   }): string | undefined {
//     if (type.name) {
//       return type.name;
//     }
//     if (type.ofType) {
//       return getRelationTo(type.ofType);
//     }
//     return;
//   }

//   // parse field
//   const fields = allFields.reduce<Record<string, TableField>>((prev, key) => {
//     const selectField = selectFields[key];
//     if (!selectField) {
//       return prev;
//     }

//     // table name will upper first case as our rule
//     const isRelationField = key[0] === key[0].toUpperCase();

//     prev[key] = {
//       name: key,
//       ...(isRelationField && {
//         // there nested type, parse to
//         to: getRelationTo(selectField.type),

//         // if array relation, args will be [{name: where}, {name: limit}...]
//         // otherwise args = []
//         type: selectField.args.length ? 'ARRAY' : 'OBJECT',

//         // assume foreignKey is that, or get from metadata // should recheck
//         foreignKey: camelCase(`${name} ${pk}`),
//       }),

//       permissions: {
//         select: true, // always true because we handle selectable field only
//         create: !!createFields[key],
//         update: !!updateFields[key],
//       },
//     };
//     return prev;
//   }, {});

//   // @@@ still dev for better parser
//   // console.log({
//   //   name,
//   //   selectFields,
//   //   createFields,
//   //   updateFields,
//   //   allFields,
//   //   pkFields,
//   //   fields,
//   // });

//   const fieldArr = Object.values(fields);
//   const mutationRoot = find(schemaTypes, { name: 'mutation_root' });
//   return {
//     name,
//     pk,
//     fields,
//     permissions: {
//       select: fieldArr.map((item) => item.permissions.select).some(Boolean),
//       create: fieldArr.map((item) => item.permissions.create).some(Boolean),
//       update: fieldArr.map((item) => item.permissions.update).some(Boolean),
//       delete: !!(mutationRoot?.fields || []).find(
//         (item: any) => item.name === `delete_${name}_by_pk`
//       ),
//     },
//   };
// }

/**
 * Parse introspction of hasura
 */
export const useParsedIntrospection = () => {
  // const { data: dbInfo } = useQuery(gql`
  //   query DBInfoQuery {
  //     db_get_tables {
  //       data
  //       key
  //     }
  //     db_get_constraints {
  //       key
  //       data
  //     }
  //   }
  // `);

  const {
    data: dbInfo,
    error,
    loading,
    refetch,
  } = useQuery(
    gql`
      query DBInfoQuery {
        db_get_tables {
          data
          key
        }
        db_get_constraints {
          key
          data
        }

        # IntrospectionQuery
        __schema {
          queryType {
            name
          }
          mutationType {
            name
          }
          subscriptionType {
            name
          }
          types {
            ...FullType
          }
          directives {
            name
            description
            locations
            args {
              ...InputValue
            }
          }
        }
      }

      fragment FullType on __Type {
        kind
        name
        description
        fields(includeDeprecated: true) {
          name
          description
          args {
            ...InputValue
          }
          type {
            ...TypeRef
          }
          isDeprecated
          deprecationReason
        }
        inputFields {
          ...InputValue
        }
        interfaces {
          ...TypeRef
        }
        enumValues(includeDeprecated: true) {
          name
          description
          isDeprecated
          deprecationReason
        }
        possibleTypes {
          ...TypeRef
        }
      }

      fragment InputValue on __InputValue {
        name
        description
        type {
          ...TypeRef
        }
        defaultValue
      }

      fragment TypeRef on __Type {
        kind
        name
        ofType {
          kind
          name
          ofType {
            kind
            name
            ofType {
              kind
              name
              ofType {
                kind
                name
                ofType {
                  kind
                  name
                  ofType {
                    kind
                    name
                    ofType {
                      kind
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const data = useMemo(() => {
    if (!dbInfo) {
      return;
    }
    // const types = intro.__schema.types;
    // const { tables } = getIndexedSchema(types);
    // const result = tables.reduce<Record<string, IntroTable>>((prev, key) => {
    //   const introTable = getIntroTable(key, types);
    //   if (introTable) {
    //     prev[key] = introTable;
    //   }
    //   return prev;
    // }, {});

    // // the extra step to set relation field permission
    // // because ARRAY relation have to use replate mutation (2 mutation for update)
    // // then we cannot found updateFields[relationFieldKey]
    // // this check permission update on relation table, the reset the field permissions
    // forEach(result, (item) => {
    //   forEach(item.fields, (field) => {
    //     if (field.to) {
    //       field.permissions.update =
    //         result[field.to] && result[field.to].permissions.update;
    //     }
    //   });
    // });

    // return result;

    return getDataRelation({
      tables: dbInfo.db_get_tables,
      constraints: dbInfo.db_get_constraints,
      schema: dbInfo.__schema,
    });
  }, [dbInfo]);

  return {
    loading,
    error,
    data,
    refetch,
  };
};
