import config from '../modules/dynamic-config';

export type MediaFile = { filename: string; [key: string]: unknown };

/**
 * Try to get media url from first array of single file
 */
export const getMediaUrl = (
  file?: MediaFile | MediaFile[],
  size: 'icon' | 'small' | 'large' | 'original' = 'large',
  fallback: string | false | undefined = config.defaultAvatar
) => {
  const firstFile = file ? (Array.isArray(file) ? file[0] : file) : null;
  if (!firstFile) {
    return fallback;
  }
  return config.getMediaUrl(file as any, size) || fallback;
};

export function getBase64(file: File): Promise<string | undefined> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}
