import React, { useEffect, useMemo } from 'react';
import useCurrentUser from '../../modules/app/useCurrentUser';
import { gql, useSubscription } from '@apollo/client';
import { Badge, Button, Dropdown, MenuProps, Space, Typography } from 'antd';
import moment from 'moment';
import { MessageOutlined } from '@ant-design/icons';
import ConversationUnread from './ConversationUnread';
import useSubscribeToConversationsUnreadCount, {
  useTotalUnread,
} from '../../components/Conversation/hooks/useSubscribeToConversationUnreadCount';

const BOOKING_LAST_MESSAGE = gql`
  subscription BookingLastMessage {
    BookingLastMessage(limit: 30, order_by: { createdOn: desc }) {
      id
      updatedOn
      userId
      createdOn
      conversationId
      bookingId
      content
      attachments
      User {
        id
        name
        phoneNumber
        email
      }
      Booking {
        id
        code
      }
    }
  }
`;

export default function BookingLastMessage() {
  const { data: currentUser } = useCurrentUser();
  const { data: messageData } = useSubscription(BOOKING_LAST_MESSAGE, {
    skip: !currentUser,
  });

  useSubscribeToConversationsUnreadCount();
  const total = useTotalUnread();

  const items: MenuProps['items'] = useMemo(() => {
    const messages = messageData?.BookingLastMessage || [];
    return messages.map((item: any) => {
      const name = item.User?.name || item.User?.email || 'Unknown';
      // const avatar = item.User?.avatar;
      const createdOn = moment(item.createdOn);
      const code = `JR-${item.Booking?.code}`;
      return {
        key: item.id,
        label: (
          <a href={`/page/tracking?code=${code}#support`} target="_blank">
            <Space direction="vertical" size={0} style={{ maxWidth: 250 }}>
              <Space direction="horizontal" align="center">
                <Typography.Text strong>{code}</Typography.Text>
                <ConversationUnread conversationId={item.conversationId} />
              </Space>
              <Typography.Text type="secondary">
                <small style={{ fontWeight: 500 }}>
                  {name || '--'}, {createdOn.format('hh:mm A')}
                </small>
              </Typography.Text>
              <Typography.Text>{item.content}</Typography.Text>
            </Space>
          </a>
        ),
      };
    });
  }, [messageData]);

  // this hard to track when need to refetch because the lastMessage also changed, not only unread
  // so change to use subscription
  // const cIds = Object.keys(uc);
  // const itemConversationIds = (messageData?.BookingLastMessage || []).map(
  //   (item: any) => item?.conversationId
  // );
  // const difLength = difference(cIds, itemConversationIds).length;
  // useEffect(() => {
  //   if (loading || !difLength) {
  //     return;
  //   }
  //   console.log('trigger refetch BOOKING_LAST_MESSAGE');
  //   refetch();
  // }, [difLength, loading, refetch]);

  return (
    <Dropdown menu={{ items }}>
      <Badge count={total}>
        <Button icon={<MessageOutlined />} style={{ marginLeft: 8 }}>
          Support Messages
        </Button>
      </Badge>
    </Dropdown>
  );
}
