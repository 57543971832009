import {
  $getRoot,
  $getSelection,
  $insertNodes,
  EditorState,
  LexicalEditor,
  REMOVE_TEXT_COMMAND,
} from 'lexical';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { useCallback, useEffect } from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { InputBaseProps } from '../../../utils/common';
import React from 'react';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import exampleTheme from './themes/ExampleTheme';
import './RichTextInput.css';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import PlaygroundAutoLinkPlugin from './plugins/AutoLinkPlugin';
import useControllableState from '../../../hooks/useControllableState';

export type RichTextInputProps = InputBaseProps<string>;

const editorConfig = {
  namespace: 'MyEditor',
  // The editor theme
  theme: exampleTheme,
  // Handling of errors during update
  onError: console.error,
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
  ],
};

/**
 * https://lexical.dev/docs/concepts/serialization#html---lexical
 */
function InitPlugin(props: { html?: string }) {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    if (props.html) {
      editor.update(() => {
        const parser = new DOMParser();
        const dom = parser.parseFromString(props.html as string, 'text/html');
        const nodes = $generateNodesFromDOM(editor, dom);
        const root = $getRoot();
        root.select();

        // @@@ will research
        // it not throw error, but cannot remove line break
        root.getChildren().forEach((node) => node.clear());
        // editor.dispatchCommand(REMOVE_TEXT_COMMAND, undefined);

        $insertNodes(nodes);
      });
    }
  }, []);
  return null;
}

export default function RichTextInput(props: RichTextInputProps) {
  const onEditorChange = useCallback(
    (editorState: EditorState, editor: LexicalEditor) => {
      if (editor) {
        editorState.read(() => {
          props.onChange?.($generateHtmlFromNodes(editor));
        });
      }
    },
    []
  );

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className="editor-container">
        <ToolbarPlugin />
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={
              <div className="editor-placeholder">Enter some text...</div>
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          <CodeHighlightPlugin />
          <ListPlugin />
          <LinkPlugin />
          <PlaygroundAutoLinkPlugin />
          <OnChangePlugin onChange={onEditorChange} ignoreSelectionChange />
          <InitPlugin html={props.value} />
        </div>
      </div>
    </LexicalComposer>
  );
}
