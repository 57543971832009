import { gql } from '@apollo/client';
import { isEmpty } from 'lodash';
import { IntroTable, TableField } from '../hasura/types';
import { ColField, TableSettings } from './types';

/**
 * The merge of specs and data settings
 * To a resource to use by queryHooks, getFormFields, getTableColumns
 */
export default function getResources({
  specs,
  dataSettings,
}: {
  dataSettings?: Record<string, TableSettings>;
  specs: Record<string, IntroTable>;
}) {
  function getTableResource(name: string) {
    const spec = specs[name];
    const dataSetting = dataSettings?.[name];

    // no permissions on any fields
    if (isEmpty(spec.fields)) {
      return;
    }

    /**
     * Split fields to relationFields & ownFields
     */
    const { relationFields, ownFields } = Object.entries(spec.fields).reduce(
      (prev, [key, field]) => {
        if (field.to) {
          prev.relationFields[key] = field;
        } else {
          prev.ownFields[key] = field;
        }
        return prev;
      },
      {
        relationFields: {} as Record<string, TableField>,
        ownFields: {} as Record<string, TableField>,
      }
    );

    /**
     * Pick colFields by allowed fields and has data settings
     */
    const colFields = Object.keys(spec.fields).reduce<Record<string, ColField>>(
      (prev, key) => {
        if (dataSetting?.cols?.[key]?.type) {
          prev[key] = {
            col: dataSetting.cols[key],
            field: spec.fields[key],
          };
        }
        return prev;
      },
      {}
    );

    /**
     * Common docs
     */
    const docs = (() => {
      const pk = spec.pk;
      const pkType = pk === 'id' ? 'uuid' : 'String';

      // Helper to get defined field names of table in spec
      // this not incluce relation fields
      // use to buil graphql query
      const getTableSpecFieldNames = (tableName: string) => {
        const fields = specs[tableName].fields;
        return Object.keys(fields).filter((key) => !fields[key].to);
      };

      const tableGql = getTableSpecFieldNames(name).join('\n');
      const relationGqls = Object.keys(relationFields).map((key) => {
        const field = spec.fields[key];
        return `
        ${field.name} {
          ${getTableSpecFieldNames(field.to as string).join('\n')}
        }
      `;
      });

      return {
        load: gql`
          query Load${name}($pk: ${pkType}! = "") {
            ${name}_by_pk(${pk}: $pk) {
              ${tableGql}
              ${relationGqls}
            }
          }
        `,
        search: gql`
          query Search${name}(
            $where: ${name}_bool_exp = {}
            $limit: Int = 10
            $offset: Int = 0
            $orderBy: [${name}_order_by!] = {}
          ) {
            ${name}_aggregate(where: $where) {
              aggregate {
                count
              }
            }
            ${name}(
              where: $where
              limit: $limit
              offset: $offset
              order_by: $orderBy
            ) {
              ${tableGql}
            }
          }
        `,
        create: gql`
          mutation Create${name}($data: ${name}_insert_input! = {}) {
            insert_${name}_one(object: $data) {
              ${tableGql}
              ${relationGqls}
            }
          }
        `,
        update: gql`
          mutation Update${name}($pk: ${pkType}! = "", $data: ${name}_set_input! = {}) {
            update_${name}_by_pk(pk_columns: { ${pk}: $pk }, _set: $data) {
              ${tableGql}
              ${relationGqls}
            }
          }
        `,
        updateMany: gql`
          mutation UpdateMany${name}($where: ${name}_bool_exp!, $data: ${name}_set_input! = {}) {
            update_${name}(where: $where, _set: $data) {
              returning {
                ${tableGql}
                ${relationGqls}
              }
            }
          }
        `,
        delete: gql`
          mutation Delete${name}($pk: ${pkType}! = "") {
            delete_${name}_by_pk(${pk}: $pk) {
              ${tableGql}
            }
          }
        `,
        deleteMany: gql`
          mutation DeleteMany${name}($where: ${name}_bool_exp!) {
            delete_${name}(where: $where) {
              returning {
                ${tableGql}
              }
            }
          }
        `,
        replace: gql`
          mutation Replace${name}($where: ${name}_bool_exp!, $data: [${name}_insert_input!] = {}) {
            delete_${name}(where: $where) {
              affected_rows
            }
            insert_${name}(objects: $data) {
              affected_rows
            }
          }
        `,
      };
    })();

    return {
      spec,
      dataSetting,
      ownFields,
      relationFields,
      colFields,
      docs,
    };
  }
  return Object.keys(specs).reduce<
    Record<string, Exclude<ReturnType<typeof getTableResource>, undefined>>
  >((prev, key) => {
    const r = getTableResource(key);
    if (!r) {
      return prev;
    }
    prev[key] = r;
    return prev;
  }, {});
}

export type DataResources = ReturnType<typeof getResources>;
export type TableResource = DataResources[string];
