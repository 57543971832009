import { useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useCallback } from 'react';

const INSERT = gql`
  mutation InsertKeyDataMutation($object: KeyData_insert_input! = {}) {
    insert_KeyData_one(
      object: $object
      on_conflict: { constraint: KeyData_pkey, update_columns: data }
    ) {
      data
      key
    }
  }
`;

const LOAD = gql`
  query LoadKeyDataQuery($key: String!) {
    KeyData_by_pk(key: $key) {
      data
      key
    }
  }
`;

const UPDATE = gql`
  mutation UpdateKeyDataMutation(
    $key: String!
    $_append: KeyData_append_input = {}
    $_set: KeyData_set_input = {}
    $_delete_at_path: KeyData_delete_at_path_input = {}
  ) {
    update_KeyData_by_pk(
      pk_columns: { key: $key }
      _append: $_append
      _set: $_set
      _delete_at_path: $_delete_at_path
    ) {
      data
      key
    }
  }
`;

export const useKeyData = <T = any>(key?: string) => {
  const loadResult = useQuery(LOAD, {
    variables: {
      key,
    },
    skip: !key,
  });
  const [update, updateResult] = useMutation(UPDATE);
  const [insert, insertResult] = useMutation(INSERT);
  const data: T = loadResult.data?.KeyData_by_pk?.data;

  /**
   * update with fallback
   */
  const save = useCallback(
    async (action: { set?: any; append?: any }) => {
      const updateResult = await update({
        variables: {
          key,
          ...(action.set
            ? {
                _set: {
                  data: action.set,
                },
              }
            : {}),
          ...(action.append
            ? {
                _append: {
                  data: action.append,
                },
              }
            : {}),
        },
      });
      if (updateResult.data?.update_KeyData_by_pk !== null) {
        return updateResult;
      }

      const insertResult = await insert({
        variables: {
          object: {
            key,
            data: {
              ...action.set,
              ...action.append,
            },
          },
        },
      });
      return insertResult;
    },
    [key, update, insert]
  );

  return {
    data,
    save,
    loading: loadResult.loading || updateResult.loading || insertResult.loading,
    loaded: Boolean(loadResult.data),
    refetch: loadResult.refetch,
  };
};
